<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="700px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="120px"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="问题描述：" prop="description">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.description }}</p>
                <el-input clearable v-else v-model="ruleForm.description" placeholder="请输入问题描述" maxlength=600 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="问题截图：" prop="imageUrl" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.imageUrl"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.imageUrl">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.imageUrl.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="问题录屏：" prop="videoUrl" class="my-upload-files">
                <div class="all-video" v-for="(item, index) in ruleForm.videoUrl" :key="index">
                  <video :src="item" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
                </div>
                <div v-if="editType == 'look' && !ruleForm.videoUrl.length" class="no-data">无视频</div>
                <div v-if="editType != 'look'" @click="addVids" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="联系人：" prop="linkMan">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.linkMan }}</p>
                <el-input clearable v-else v-model="ruleForm.linkMan" placeholder="请输入联系人" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="手机号：" prop="phone">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.phone }}</p>
                <el-input clearable v-else v-model="ruleForm.phone" placeholder="请输入手机号" maxlength=14 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="手机验证码：" prop="verificationCode">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.verificationCode }}</p>
                <el-input v-else :class="sendFlag ? 'my-input-btn' :  'my-input-btn-gray'" v-model="ruleForm.verificationCode" placeholder="请输入手机验证码">
                  <el-button slot="append" v-if="sendFlag" @click="sendMsg('phone')" type="primary">获取验证码</el-button>
                  <el-button slot="append" v-else class="btn-gray"  type="primary">{{countdown}}秒后重新发送</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <p class="tips-gray pl20">验证码10分钟有效</p>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm()">保存</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
    <pics ref="pics" dictCode="301001" :businessCode="ruleForm.feedbackId" :imgSize="0.5" propName="imageUrl" storeTableName="fileTable" @getpics="getpics"></pics>
    <vids ref="vids" dictCode="301002" :businessCode="ruleForm.feedbackId" propName="videoUrl" storeTableName="fileTable" @getpics="getpics"></vids>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import pics from '@/views/common/pics.vue'
import vids from '@/views/common/vids.vue'
export default {
  name: "feedbackEdit",
  components: {
    pics,
    vids,
  },
  props:{
  },
  data() {
    return {   
      rules: {
        description: [{ required: true,  trigger: "change", message: '请输入问题描述'}],
        linkMan: [{ required: true,  trigger: "change", message: '请输入联系人'}],
        verificationCode: [{ required: true,  trigger: "change", message: '请输入验证码'}],
        phone: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入手机号"))
            }else if(!baseValidate.tel(val)){
              callback(new Error("请输入正确的手机号"))
            }else{
              callback()
            }
          }
        }]
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: 'add',
      ruleForm: {},
      initForm: {
        feedbackId: '',
        description: '',
        imageUrl: [],
        videoUrl: [],
        phone: '',
        fileTable: '',
        linkMan: '',
        userName: utils.getUserName(),
        bodyName: utils.getBodyName(),
      },
      countdown: CONSTPARAMS.countdown,
      sendFlag: true,
    };
  },
  created() {
	},
  mounted() {
  },
  methods: {
    sendMsg(prop){
      utils.sendMsg(this, prop, 'feedback')
    },
    addPics(){
      this.$refs.pics.initData(this.ruleForm.imageUrl)
    },
    addVids(){
      this.$refs.vids.initData(this.ruleForm.videoUrl)
    },
    getpics(data){
      this.ruleForm[data.propName] = data.pics
      this.ruleForm[data.storeTableName] = data.tableName
      this.$refs.ruleForm.validateField(data.propName);
    },
    async initData(title, editType, ruleForm, transobj = {}){
      if(editType == 'add'){
        let id = await utils.getNewId(),
          obj = {
          ...this.initForm,
          ...transobj,
          feedbackId: id,
        }
        this.ruleForm = obj
      }else{
        this.ruleForm = ruleForm
      }
      this.title = title
      this.editType = editType
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
    },
    initLook(){
      this.initEdit()
    },
    async submitForm() {
      let _this = this,
        formName = 'ruleForm'
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitData()
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    async submitData(){
      let _this = this,
        editType = this.editType,
        mesInfo = {
          add: "新增成功！",
          edit: "修改成功！"
        }
      _this.loading = true
      let flag = await utils.codeCheck('feedback', _this.ruleForm.phone, _this.ruleForm.verificationCode) || false
      if(!flag) {
          _this.loading = false 
          return
        }
      let ways = editType == 'add' ? 'feedbacksave' : 'updateharvestrecord',
        target = {
        ..._this.ruleForm,
        imageUrl: _this.ruleForm.imageUrl?.join(',') || '',
        videoUrl: _this.ruleForm.videoUrl?.join(',') || '',
      }
      delete target.verificationCode
      
      request[ways](target).then((res) => {
        if(res.code == 200){
          _this.message(mesInfo[editType], "success")
          _this.closeModel()
          _this.$emit("getData", editType == 'add' ? true : false)
        }else{
          _this.message(res.msg || '', "error")
        }
        _this.loading = false
      }).catch(e => {
        console.log(e)
        _this.loading = false
      })
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
</style>


