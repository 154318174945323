<template>
  <div class="top-menu">
    <el-menu 
      :default-active="activeIndex" 
      class="el-menu-demo" 
      mode="horizontal" 
      @select="handleSelect" 
    >
      <!-- background-color="#2f80cf"
      text-color="#fff" 
      active-text-color="#fff" -->
      <template v-for="(item, index) in menuList">
        <el-menu-item :key="index + 'el0'"  v-if="!item.children" :index="item.id">{{item.name}}</el-menu-item>
        <el-submenu :key="index + 'el1'"  v-else :index="item.id">
          <template slot="title">{{item.name}}</template>
          <template v-for="(cItem, index1) in item.children">
            <el-menu-item :key="cItem.id" :index="cItem.id">{{cItem.name}}</el-menu-item>
            <!-- <p :key="index1" v-if="cItem.hasLine" class="dash-line">---------------------</p> -->
            <p class="menu-btn el-menu-item" :key="index1" v-if="cItem.hasText" @click="handleClick(cItem.funName)"> {{ cItem.hasText }} </p>
            <p class="menu-btn el-menu-item" :key="index1 + 'el2'" v-if="cItem.hasText2" @click="handleClick(cItem.funName2)"> {{ cItem.hasText2 }} </p>
          </template>
        </el-submenu>
      </template> 
    </el-menu>
    <changepassword ref="changepassword"></changepassword>
    <feedback-edit ref="feedbackEdit" @getData="getData"></feedback-edit>
  </div>
</template>
<script>
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  import changepassword from '@/views/system/changepassword.vue'
  import feedbackEdit from '@/views/system/feedbackEdit.vue'
  export default {
    name: 'topNav',
    components: {
      changepassword,
      feedbackEdit
    },
    data() {
      return {
        menuList:[
          {
            name: '首页',
            path: '/',
            id: '1'
          },
          {
            name: '承诺达标合格证管理',
            id: '2',
            children: [
              {
                name: '开具纸质合格证（打印纸质标签）',
                path: '/papersList',
                id: '2-1'
              },
              {
                name: '开具电子合格证（激活印刷标签）',
                path: '/onlineList',
                id: '2-2'
              },
              {
                name: '开具数字合格证（无纸化转发）',
                path: '/nopaperList',
                id: '2-10'
              },
              {
                name: '配送清单批量打印合格证（配送企业使用）',
                path: '/deliveryList',
                id: '2-11'
              },
              {
                name: '电子合格证标签免费领取',
                path: '/freeList',
                hasLine: true,
                id: '2-3'
              },
              {
                name: '我要采购',
                path: '/buyList',
                id: '2-4'
              },
              {
                name: '我要销售',
                path: '/saleList',
                id: '2-5'
              },
              {
                name: '我的供应商',
                path: '/mysupplyList',
                id: '2-6'
              },
              {
                name: '我的客户',
                path: '/clientList',
                hasLine: true,
                id: '2-7'
              },
              {
                name: '合格证打印记录',
                path: '/printlog',
                id: '2-8'
              },
              {
                name: '合格证使用统计',
                path: '/usestatistic',
                id: '2-9'
              }
            ]
          },
          {
            name: '生产档案管理',
            id: '3',
            children: [
              {
                name: '投入品购买管理',
                path: '/inputsList',
                id: '3-1'
              },
              {
                name: '创建产品批次档案',
                path: '/batchList',
                id: '3-2'
              },
              {
                name: '药品使用',
                path: '/drugsList',
                id: '3-3'
              },
              {
                name: '肥料/饲料使用',
                path: '/fodderList',
                id: '3-4'
              },
              {
                name: '产品检测',
                path: '/testList',
                id: '3-5'
              },
              {
                name: '采收/出栏记录',
                path: '/harvestList',
                id: '3-6'
              },
              {
                name: '日常农事或加工记录',
                path: '/farmList',
                hasLine: true,
                id: '3-7'
              },
              {
                name: '投入品库存',
                path: '/storeList',
                id: '3-8'
              },
              {
                name: '投入品入库记录',
                path: '/importList',
                id: '3-9'
              },
              {
                name: '投入品出库记录',
                path: '/exportList',
                id: '3-10'
              },
              {
                name: '投入品供应商',
                path: '/supplyList',
                id: '3-11'
              }
            ]
          },
          {
            name: '主体信息',
            id: '4',
            children: [
              {
                name: '主体信息',
                path: '/maininfo',
                id: '4-1'
              },
              {
                name: '资质信息',
                path: '/qualifyList',
                id: '4-2'
              },
              {
                name: '产品信息',
                path: '/productList',
                id: '4-3'
              },
              {
                name: '基地信息',
                path: '/baseList',
                id: '4-4'
              },
              {
                name: '基地地块/栏舍信息',
                path: '/baseLists',
                id: '4-5'
              }
            ]
          },
          {
            name: '系统管理',
            id: '5',
            children: [
              {
                name: '我的信息',
                path: '/myInfo',
                id: '5-1'
              },
              {
                name: '账号管理',
                id: '5-2',
                path: '/accountList',
                hasText: '修改密码',
                funName: 'editPassword',
                hasText2: '问题反馈',
                funName2: 'feedbackEdit'
              },
              {
                name: '操作说明',
                path: '/operateList',
                id: '5-5'
              },
              {
                name: '技术规范',
                id: '5-6',
                path: '/tecList',
                hasText: '取消微信绑定',
                funName: 'unbind'
              },
              {
                name: '发票管理',
                path: '/invoiceList',
                id: '5-7'
              }
            ]
          },
        ],
        activeIndex: '1',
        newObj: {},
        oldObj: {},
        fullPath: '',
        chooseList: [],
        curId: ''
      }
    },
    methods: {
      handleSelect(val){
        let data = this.menuList,
          path = this.getPathByid(data, val)
        if(path){
          this.$router.push({
            path: path,
            // query:{
            //   id:this.id
            // }
          })
          this.curId = this.newObj.id
          this.getChooseList()
          this.oldObj = { ...this.newObj }
        }else{
          this.message("暂未开发", 'warning')
          this.activeIndex = this.oldObj?.id || '1'
        }
      },
      delitem(i){
        this.chooseList.splice(i, 1)
      },
      getChooseList(){
        let list = this.chooseList || [],
          curItem = this.newObj,
          flag = true
        list.forEach(cur => {
          if(cur.id == curItem.id){
            flag = false
          }
        })
        if(flag){
          list.push({ ...this.newObj })
        }
        this.$emit('getList', {
          list: list,
          curId: this.curId
        })
      },
      handleClick(name){
        this[name]()
      },
      checkIsLogin(){
        let flag = Boolean(localStorage.getItem("userInfo"))
        if(!flag){
          this.message('暂未登录，即将跳转到登录页面...', 'error')
          setTimeout(() => {
            this.$router.push({
              path: '/login',
              query: { redirect: this.fullPath }
            })
          }, 1000)
        }
        return flag
      },
      editPassword(){
        let flag = this.checkIsLogin()
        if(!flag) return
        this.$refs.changepassword.initData()
      },
      getData(){},
      feedbackEdit(){
        this.$refs.feedbackEdit.initData(`新增问题反馈`, 'add')
      },
      unbind(){
        let flag = this.checkIsLogin()
        if(!flag) return
        let _this = this
        this.$confirm(`确认要取消绑定微信吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          _this.unbindWe()
        }).catch(() => {})
      },
      unbindWe(){
        request.wechatunBind({
          userName: utils.getUserName()
        }).then(res => {
          if(res.code == 200){
            this.message("取消成功！", 'success')
          }else {
            this.message(res.msg || '', 'error')
          }
        })
      },
      getPathByid(data, val, prop = 'id'){
        let path = '',
          _this = this
        function getPath(data, val, prop){
          if(data && data.length){
            for(let i = 0; i < data.length; i++){
              let item = data[i]
              if(item[prop] == val){
                path =  item.path
                _this.newObj = { ...item }
              }else if(item.children && item.children.length){
                getPath(item.children, val, prop)
              }
            }
          }
        }
        getPath(data, val, prop)
        return path
      },
      linkTohome(){
        this.getPathByid(this.menuList, '/', 'path')
        this.activeIndex = '1'
        this.curId = '1'
        this.getChooseList()
      }
    },
    created() {
      // console.log(this.$route.path)
      if(this.$route.path){
        this.getPathByid(this.menuList, this.$route.path, 'path')
        this.activeIndex = this.newObj?.id || '1'
        this.curId = this.newObj?.id || '1'
        this.getChooseList()
      }
    },
    beforeUpdate() {},
    beforeMount() {
    },
    watch: {
      $route(to, from) {
        this.fullPath = to.fullPath
      }
    }
  }
</script>
<style lang="scss" scoped>
  .dash-line{
    padding-left: 10px;
    color: gray;
  }
  .top-menu ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow{
    color: #fff;
    display: none;
  }
  // .top-menu ::v-deep .el-menu--horizontal>.el-menu-item.is-active, .el-menu--horizontal>.is-active,
  // .top-menu ::v-deep .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
  //   border-bottom-color: transparent;
  //   color: #ebf710;
  // }
  .top-menu ::v-deep .el-menu--horizontal>.el-menu-item, 
  .top-menu ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title{
    color: #fff;
    height: 30px;
    line-height: 30px;
  }
  .el-menu-demo{
    background-color: transparent;
  }
  .top-menu ::v-deep .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, 
  .top-menu ::v-deep .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, 
  .top-menu ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title:hover,
  .top-menu ::v-deep .el-menu--horizontal>.el-menu-item.is-active, .el-menu--horizontal>.is-active,
  .top-menu ::v-deep .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    background-color: transparent;
    color: #ebf710;
    // border-bottom: none;
  }

  .top-menu ::v-deep .el-menu--horizontal>.el-menu-item.is-active{
    border-bottom: transparent;
  }

  .top-menu ::v-deep .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border-bottom: 2px solid transparent
  }
  
  .el-menu--horizontal .el-menu .el-menu-item.is-active,
  .el-menu--horizontal .el-menu .el-menu-item:hover{
    color: #fff;
    background-color: #3FA9F5;
  }

  .top-menu ::v-deep .el-menu.el-menu--horizontal{
    border-bottom: transparent;
  }
  .menu-btn{
    height: 36px;
    line-height: 36px;
    padding: 0 10px;
    color: #909399;
    font-size: 14px;
    cursor: pointer;
  }
  .menu-btn:hover{
    color: #303133;
  }
  
</style>