import axios from 'axios'
import qs from 'qs'
import { Message } from 'element-ui'
import CONSTPARAMS from './const'
const service =  axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 30,
  headers: {
    get: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    },
    post: {
      'Content-Type': 'application/json;charset=utf-8'
    }
  }
})

//获取url各属性值
const getParam = () => {
	const url = window.location.href;
	let res = {};
	url.replace(/[?|&](\w+)=([^&#]*)/g, (all, key, val) => {
		res[key] = val;
		return all;
	})
	return res;
}

const getToken = () => {
	return window.localStorage.getItem('myToken') || ''
}

service.interceptors.request.use(config => {
  // let userInfo = localStorage.getItem('userInfo'),
  //   bodyId = null
  // if(userInfo){
  //   bodyId = JSON.parse(userInfo)?.bodyInfo?.bodyId
  // }
  // if(!bodyId){
  //   Message.error({message: '暂未登录，即将跳转到登录页面...'})
  //   setTimeout(() => {
  //     location.href = '#/login'
  //   }, 2000)
  // }
  // config.headers.inletToken = inletToken
  let urls = [`auth/signin`, 
      `auth/signin/wechat`, 
      `auth/signin/mobile`, 
      `sys/sms/check`, 
      `sys/sms/send`
    ],
    flag = CONSTPARAMS.needToken
  if(config.url && !urls.includes(config.url)){
    flag && (config.headers.satoken = getToken())
  }
  // config.url = `/api/${config.url}`
  return config
}, error => {
  return Promise.reject(error)
})

service.interceptors.response.use(response => {
  if (response.status === 200) {
    // if (response.data.code === 511) {
    // } else if (response.data.code === 510) {
    // } else {
    // }
    return Promise.resolve(response.data)
  } else {
    Promise.reject(response.data)
  }
}, error => {
  let response = error.response,
    msg = error.message
  if(response){
    let state = error.response.status,
      cont = `${state}${CONSTPARAMS.statusObj[state]}`
    Message.error({message: cont})
    return Promise.reject(error.response.data)
  }else{
    if(msg.indexOf('timeout') > -1){
      Message.error({message: `504${CONSTPARAMS.statusObj['504']}`})
      return Promise.reject(false)
    }
    Message.error({message: '网络连接失败，请稍后再试!'})
    return Promise.reject(false)
  }
})

export const get = (url, params) => {
  return service.get(`${url}`, {
    params: params
  })
}

function stringObjUrl(url, params){
  if(params && (typeof params === 'object')){
    let temp = []
    for(let o in params){
      temp.push(`${o}=${params[o]}`)
    }
    if(temp.join('&')){
      return `${url}?${temp.join('&')}`
    }
  }else if(params){
    return `${url}${params}`
  }
  return `${url}`
}

export const getHead = (url, params, headObj) => {
  return service({
    method: 'get',
    url: stringObjUrl(url, params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      ...headObj
    }
  })
}

export const postJson = (url, params) => {
  return service({
    method: 'post',
    url: `${url}`,
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const postJsonHead = (url, params, headObj) => {
  return service({
    method: 'post',
    url: `${url}`,
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      ...headObj
    }
  })
}

export const post = (url, params, tout = 30000) => {
  return service({
    method: 'post',
    url: `${url}`,
    data: params,
    timeout: tout,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [
      function (data) {       
        //return qs.stringify(data)
        let ret = ''
        for (let it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
      }
    ],
  })
}

export const put = (url, params) => {
  return service({
    method: 'put',
    url: `${url}`,
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const putHead = (url, params, headObj, tout = 30000) => {
  return service({
    method: 'put',
    url: `${url}`,
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      ...headObj
    },
    timeout: tout
  })
}

export const del = (url, params, tout = 30000) => {
  return service({
    method: 'delete',
    url: stringObjUrl(url, params),
    timeout: tout
  }) 
}

export const delHead = (url, params, headObj, tout = 30000) => {
  return service({
    method: 'delete',
    url: `${url}`,
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      ...headObj
    },
    timeout: tout
  })
}

export const delJson = (url, params, tout = 30000) => {
  return service({
    method: 'delete',
    url: `${url}`,
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: tout
  }) 
}

// export const $getExport = (url, params, tout = 999999) => {
//   return service({
//     method: 'post',
//     url: `${url}`,
//     data: params,
//     timeout: tout,
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded'
//     },
//     transformRequest: [
//       function (data) {
//         return qs.stringify(data)
//       }
//     ],
//     responseType: 'blob'
//   })
// }

export const $getExport = (url, params, tout = 999999) => {
  return service({
    method: 'post',
    url: `${url}`,
    data: JSON.stringify(params),
    timeout: tout,
    headers: {
      'Content-Type': 'application/json'
    },
    responseType: 'blob'
  })
}

// 获取返回文件
export const getFile = (url, params) => {
  return service({
    method: 'get',
    url: stringObjUrl(url, params),
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    responseType: 'blob'
  })
}

// 获取返回文件-json
export const getPostFile = (url, params) => {
  return service({
    method: 'get',
    url: `${url}`,
    params: params,
    headers: {
      'Content-Type': 'application/json'
    },
    responseType: 'blob'
  })
}

//导出
export const getExport = (url, params, tout = 999999) => {
  return new Promise((resolve, reject) => {
    return $getExport(url, params, tout).then(res=> {
        if (res.type === "application/json") {
          const reader = new FileReader()
          reader.readAsText(res, "utf-8")
          reader.onload = () => {
            let resData = JSON.parse(reader.result);
            Message.error({message: resData.msg})
            reject(false)
          }
        } else {
          resolve(res)
        }
    })
  })
}

// 上传文件
export const uploadFiles = (url, params, onUploadProgress, tout = 999999) => {
  return service({
    method: 'post',
    url: `${url}`,
    data: params,
    headers: {
      "Content-Transfer-Encoding": "binary",
      "Content-Type": "multipart/form-data",
      "Content-Disposition": "form-data"
    },
    timeout: tout,
    responseType: 'blob',
    onUploadProgress
  })
}

//上传文件并返回结果
export const uploadGetFiles = (url, params, uploadVideoProcess, tout = 999999) => {
  return new Promise((resolve, reject) => {
    return uploadFiles(url, params, uploadVideoProcess, tout).then(res=> {
      if (res.type === "application/json") {
        const reader = new FileReader()
        reader.readAsText(res, "utf-8")
        reader.onload = () => {
          let resData = JSON.parse(reader.result)
          // Message.error({message: resData.msg})
          resolve(resData)
        }
      } else {
        if(res.size > 27){
          reject(false)
        }else{
          resolve(res)
        }
      }
    }).catch((e) => {
      if (e.type === "application/json") {
        const reader = new FileReader()
        reader.readAsText(e, "utf-8")
        reader.onload = () => {
          let resData = JSON.parse(reader.result)
          reject(false)
        }
      } else {
        reject(false)
      }
      reject(false)
    })
  })
}

export const httpMethods= {
  get,
  postJson,
  post,
  put,
  del,
  getExport,
  uploadFiles,
  uploadGetFiles,
  getFile,
  getPostFile,
  getHead,
  delHead,
  postJsonHead,
  putHead,
  delJson,
  $getExport
}