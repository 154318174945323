import { render, staticRenderFns } from "./vids.vue?vue&type=template&id=1899f223&scoped=true"
import script from "./vids.vue?vue&type=script&lang=js"
export * from "./vids.vue?vue&type=script&lang=js"
import style0 from "./vids.vue?vue&type=style&index=0&id=1899f223&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1899f223",
  null
  
)

export default component.exports