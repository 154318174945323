let CONSTPARAMS = {
  statusObj: {
    400: '错误的请求或者不存在的域名，请联系技术支持单位！',
    401: '请重新登录',
    404: '请求的接口不存在，请联系技术支持单位！',
    403: '未授权禁止访问，请联系技术支持单位！',
    405: '服务请求方式失败，请联系技术支持单位！',
    406: '服务非法请求，请联系技术支持单位！',
    408: '请求超时，请稍后重试或联系技术支持单位！',
    500: '服务器错误，请稍后重试或联系技术支持单位！',
    501: '服务未实现，请联系技术支持单位！',
    502: '网络错误，请稍后重试或联系技术支持单位！',
    503: '服务不可用，请联系技术支持单位！',
    504: '网络超时，请稍后重试或联系技术支持单位！',
    505: 'HTTP版本不受支持，请联系技术支持单位！',
  },
  domain: '',
  scanUrl: 'http://www.cncsc.cn/ct?cd=', //扫码
  needToken: false,
  page: 1, //默认页数
  limit: 20, //每页显示条数
  staticHeight: 30, 
  countdown: 120, 
  appid: 'wxcdb2344ba8701d97',
  sysName: '农产品质量安全承诺达标合格证平台',
  inputsTypes: [{
      value: '01',
      label: '农药'
    },{
      value: '02',
      label: '肥料'
    },{
      value: '03',
      label: '兽药'
    },{
      value: '04',
      label: '饲料'
    },{
      value: '05',
      label: '疫苗'
    },{
      value: '06',
      label: '添加剂'
    },{
      value: '07',
      label: '其他'
  }],
  salesArea: [{
    value: '01',
    label: '内销'
  },{
    value: '02',
    label: '出口'
  },{
    value: '03',
    label: '内销和出口'
  }],
  salesConfirm: [{
    value: '00',
    label: '全部'
  },{
    value: '01',
    label: '已确认'
  },{
    value: '02',
    label: '未确认'
  },{
    value: '03',
    label: '已取消'
  }],
  snumUnit: [{
    value: '批',
    label: '批'
  },{
    value: '包',
    label: '包'
  },{
    value: '盒',
    label: '盒'
  },{
    value: '袋',
    label: '袋'
  },{
    value: '车',
    label: '车'
  },{
    value: '箱',
    label: '箱'
  },{
    value: '头',
    label: '头'
  },{
    value: '只',
    label: '只'
  },{
    value: '万只',
    label: '万只'
  },{
    value: '万只',
    label: '万只'
  },{
    value: '尾',
    label: '尾'
  },{
    value: '万尾',
    label: '万尾'
  },{
    value: '筐',
    label: '筐'
  },{
    value: '桶',
    label: '桶'
  },{
    value: '枚',
    label: '枚'
  },{
    value: '罐',
    label: '罐'
  },{
    value: '支',
    label: '支'
  },{
    value: '杯',
    label: '杯'
  },{
    value: '瓶',
    label: '瓶'
  }],
  saleUnit: [{
    value: '千克',
    label: '千克'
  },{
    value: '克',
    label: '克'
  },{
    value: '斤',
    label: '斤'
  },{
    value: '公斤',
    label: '公斤'
  },{
    value: '吨',
    label: '吨'
  },{
    value: '升',
    label: '升'
  },{
    value: '毫升',
    label: '毫升'
  }],
  weight:  [{
    value: '吨',
    label: '吨'
  },{
    value: '公斤',
    label: '公斤'
  },{
    value: '万只',
    label: '万只'
  },{
    value: '只',
    label: '只'
  },{
    value: '万条',
    label: '万条'
  },{
    value: '条',
    label: '条'
  }],
  numUnit: [{
    value: '条',
    label: '条'
  },{
    value: '头',
    label: '头'
  },{
    value: '棵',
    label: '棵'
  },{
    value: '只',
    label: '只'
  },{
    value: '万只',
    label: '万只'
  },{
    value: '尾',
    label: '尾'
  },{
    value: '万尾',
    label: '万尾'
  }],
  useType: [{
    value: '01',
    label: '淋洒'
  },{
    value: '02',
    label: '喷雾'
  }],
  reportType: [{
    value: '01',
    label: '自检'
  },{
    value: '02',
    label: '委托检测'
  }],
  checkType: [{
    value: '01',
    label: '快检'
  },{
    value: '02',
    label: '定量'
  }],
  decideResult: [{
    value: '01',
    label: '合格'
  },{
    value: '02',
    label: '不合格'
  }],
  checkResult: [{
    value: '01',
    label: '阴性'
  },{
    value: '02',
    label: '阳性'
  }],
  farmingType:[
    {isHidden: true, value: '01', label: '用药（农药/兽药/添加剂/疫苗）'},
    {isHidden: true, value: '02', label: '用料（肥料/肥水/饲料）'},
    {value: '03', label: '灌溉'},
    {value: '04', label: '环境消毒'},
    {value: '05', label: '除草'},
    {value: '06', label: '清理'},
    {isHidden: true, value: '07', label: '检测'},
    {isHidden: true, value: '08', label: '采收/出栏'},
    {value: '09', label: '加工'},
    {value: '99', label: '其他'},
  ],
  baseType: [
    { value: '01', label: '种植'},
    { value: '02', label: '畜禽'},
    { value: '03', label: '水产'},
    { value: '04', label: '加工'},
    { value: '05', label: '仓储'}
  ],
  slaughterUnit: [{
    value: '畜单位（头）',
    label: '畜单位（头）'
  },{
    value: '禽单位（只）',
    label: '禽单位（只）'
  },{
    value: '蛋（万只）',
    label: '蛋（万只）'
  }],
  areaUnit: [{
    value: '亩',
    label: '亩'
  }],
  businessBodyType: [
    { label: '自加工', value: '01' },
    { label: '来料加工', value: '02' },
    { label: '批发', value: '03' },
    { label: '配送', value: '04' },
    { label: '运输', value: '05' },
    { label: '仓储', value: '06' },
    { label: '屠宰场', value: '07' },
    { label: '超市', value: '08' },
    { label: '门店', value: '09' },
    { label: '网店', value: '10' },
    { label: '市场档口', value: '11' },
    { label: '饭堂/餐饮', value: '12' },
    { label: '饭店', value: '13' },
    { label: '收购（经纪人）', value: '14' },
    // { label: '以上都不是', value: '15' },
  ],
  retail: [
    { label: '是', value: '01' },
    { label: '否', value: '02' }
  ],
  bodyType: [
    { label: '生产主体', value: '11' },
    { label: '经营主体', value: '12' },
    { label: '生产/经营主体', value: '13' },
    // { label: '市场经营管理企业', value: '21' },
    // { label: '市场', value: '22' },
  ],
  bodyType2: [
    { label: '投入品门店', value: '41' }
  ],
  productionBodyType: [
    { label: '企业', value: '01' },
    { label: '合作社', value: '02' },
    { label: '家庭农场', value: '03' },
    { label: '个体户', value: '04' },
    { label: '散户', value: '05' },
  ],
  productionBodyType2: [
    { label: '生产厂家', value: '00' },
    { label: '销售商企业', value: '01' },
    { label: '个体门店', value: '02' },
    { label: '网店', value: '03' },
    { label: '散户', value: '04' }
  ],
  businessRange: [
    { label: '种植', value: 'plant' },
    { label: '畜禽', value: 'farming' },
    { label: '水产', value: 'aquatic' }
  ],
  channelType: [
    { label: '自产', value: '01' },
    { label: '收购', value: '02' }
  ],
}

export default CONSTPARAMS