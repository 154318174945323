const asyncRoutes = [
  {
    path: '/home', //首页
    component: () => import('@/views/home.vue'),
    name: 'home',
    meta: {
      title: '首页'
    }
  },
  {
    path: '/papersList', //开具纸质合格证（打印纸质标签）
    component: () => import('@/views/certificate/papersList.vue'),
    name: 'papersList',
    meta: {
      title: '开具纸质合格证（打印纸质标签）'
    }
  },
  {
    path: '/nopaperList', //开具数字合格证（无纸化转发）
    component: () => import('@/views/certificate/nopaperList.vue'),
    name: 'nopaperList',
    meta: {
      title: '开具数字合格证（无纸化转发）'
    }
  },
  {
    path: '/onlineList', //开具电子合格证（激活印刷标签）
    component: () => import('@/views/certificate/onlineList.vue'),
    name: 'onlineList',
    meta: {
      title: '开具电子合格证（激活印刷标签）'
    }
  },
  {
    path: '/usestatistic', //合格证使用统计
    component: () => import('@/views/certificate/usestatistic.vue'),
    name: 'usestatistic',
    meta: {
      title: '合格证使用统计'
    }
  },
  {
    path: '/printlog', //合格证打印和激活记录
    component: () => import('@/views/certificate/printlog.vue'),
    name: 'printlog',
    meta: {
      title: '合格证打印和激活记录'
    }
  },
  {
    path: '/storeList', //投入品库存
    component: () => import('@/views/docs/storeList.vue'),
    name: 'storeList',
    meta: {
      title: '投入品库存'
    }
  },
  {
    path: '/importList', //投入品入库记录
    component: () => import('@/views/docs/importList.vue'),
    name: 'importList',
    meta: {
      title: '投入品入库记录'
    }
  },
  {
    path: '/exportList', //投入品出库记录
    component: () => import('@/views/docs/exportList.vue'),
    name: 'exportList',
    meta: {
      title: '投入品出库记录'
    }
  },
  {
    path: '/supplyList', //投入品供应商
    component: () => import('@/views/docs/supplyList.vue'),
    name: 'supplyList',
    meta: {
      title: '投入品供应商'
    }
  },
  {
    path: '/mysupplyList', //我的供应商
    component: () => import('@/views/certificate/mysupplyList.vue'),
    name: 'mysupplyList',
    meta: {
      title: '我的供应商'
    }
  },
  {
    path: '/clientList', //我的客户
    component: () => import('@/views/certificate/clientList.vue'),
    name: 'clientList',
    meta: {
      title: '我的客户'
    }
  },
  {
    path: '/productList', //产品信息
    component: () => import('@/views/main/productList.vue'),
    name: 'productList',
    meta: {
      title: '产品信息'
    }
  },
  {
    path: '/qualifyList', //资质信息列表
    component: () => import('@/views/main/qualifyList.vue'),
    name: 'qualifyList',
    meta: {
      title: '资质信息列表'
    }
  },
  {
    path: '/batchList', //创建产品批次档案
    component: () => import('@/views/docs/batchLists.vue'),
    name: 'batchList',
    meta: {
      title: '创建产品批次档案'
    }
  },
  {
    path: '/freeList', //电子合格证标签免费领取
    component: () => import('@/views/certificate/freeList.vue'),
    name: 'freeList',
    meta: {
      title: '电子合格证标签免费领取'
    }
  },
  {
    path: '/saleList', //我要销售
    component: () => import('@/views/certificate/saleList.vue'),
    name: 'saleList',
    meta: {
      title: '我要销售'
    }
  },
  {
    path: '/buyList', //我要采购
    component: () => import('@/views/certificate/buyList.vue'),
    name: 'buyList',
    meta: {
      title: '我要采购'
    }
  },
  {
    path: '/inputsList', //投入品购买管理
    component: () => import('@/views/docs/inputsList.vue'),
    name: 'inputsList',
    meta: {
      title: '投入品购买管理'
    }
  },
  {
    path: '/drugsList', //药品使用
    component: () => import('@/views/docs/drugsList.vue'),
    name: 'drugsList',
    meta: {
      title: '药品使用'
    }
  },
  {
    path: '/fodderList', //肥料/饲料使用
    component: () => import('@/views/docs/fodderList.vue'),
    name: 'fodderList',
    meta: {
      title: '肥料/饲料使用'
    }
  },
  {
    path: '/testList', //产品检测
    component: () => import('@/views/docs/testList.vue'),
    name: 'testList',
    meta: {
      title: '产品检测'
    }
  },
  {
    path: '/harvestList', //采收/出栏记录
    component: () => import('@/views/docs/harvestList.vue'),
    name: 'harvestList',
    meta: {
      title: '采收/出栏记录'
    }
  },
  {
    path: '/farmList', //日常农事或加工记录
    component: () => import('@/views/docs/farmList.vue'),
    name: 'farmList',
    meta: {
      title: '日常农事或加工记录'
    }
  },
  {
    path: '/baseList', //基地信息
    component: () => import('@/views/main/baseList.vue'),
    name: 'baseList',
    meta: {
      title: '基地信息'
    }
  },
  {
    path: '/baseLists', //基地地块/栏舍信息
    component: () => import('@/views/main/baseLists.vue'),
    name: 'baseLists',
    meta: {
      title: '基地地块/栏舍信息'
    }
  },
  {
    path: '/maininfo', //主体信息
    component: () => import('@/views/main/maininfo.vue'),
    name: 'maininfo',
    meta: {
      title: '主体信息'
    }
  },
  {
    path: '/deliveryList', //配送清单批量打印合格证（配送企业使用）
    component: () => import('@/views/system/deliveryList.vue'),
    name: 'deliveryList',
    meta: {
      title: '配送清单批量打印合格证（配送企业使用）'
    }
  },
  {
    path: '/myInfo', //我的信息
    component: () => import('@/views/system/myInfo.vue'),
    name: 'myInfo',
    meta: {
      title: '我的信息'
    }
  },
  {
    path: '/accountList', //账号管理
    component: () => import('@/views/system/accountList.vue'),
    name: 'accountList',
    meta: {
      title: '账号管理'
    }
  },
  {
    path: '/invoiceList', //发票管理
    component: () => import('@/views/system/invoiceList.vue'),
    name: 'invoiceList',
    meta: {
      title: '发票管理'
    }
  },
  {
    path: '/tecList', //技术规范
    component: () => import('@/views/system/tecList.vue'),
    name: 'tecList',
    meta: {
      title: '技术规范'
    }
  },
  {
    path: '/operateList', //操作说明
    component: () => import('@/views/system/operateList.vue'),
    name: 'operateList',
    meta: {
      title: '操作说明'
    }
  },
]
export default asyncRoutes;