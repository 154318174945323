<template>
  <el-dialog 
    class="my-dialog" 
    title="图片裁剪" 
    width="900px"
    height="400px"
    :visible.sync="closeFlag" 
    center
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="closeModel">
      <div class="cut">
        <vue-cropper
          ref="cropper"
          :img="option.img"
          :output-size="option.size"
          :output-type="option.outputType"
          :info="true"
          :full="option.full"
          :fixed="fixed"
          :fixed-number="fixedNumber"
          :can-move="option.canMove"
          :can-move-box="option.canMoveBox"
          :fixed-box="option.fixedBox"
          :original="option.original"
          :auto-crop="option.autoCrop"
          :auto-crop-width="option.autoCropWidth"
          :auto-crop-height="option.autoCropHeight"
          :center-box="option.centerBox"
          :high="option.high"
          mode="100%"
          :max-img-size="option.max"
          :fixedBox="true"
          :canMoveBox="false"
          :infoTrue="true"
        ></vue-cropper>
      </div>
      <div class="forms-btns" slot="footer">
        <el-button type="primary" size="small" @click="finish" >裁剪</el-button >
        <el-button @click="closeModel" size="small">取消</el-button>
      </div>
  </el-dialog>
</template>

<script>
import {VueCropper} from 'vue-cropper'
export default {
  name: 'picsCrop',
  components: {
    VueCropper
  },
  props: {
  },
  data() {
    return {
      closeFlag: false,
      option: {
        img: '',
        size: 1,
        full: false,
        canMove: true,
        fixedBox: false,
        original: true,
        canMoveBox: true,
        autoCrop: true,
        // 只有autoCrop: true自动截图开启 宽度高度才生效
        autoCropWidth: 480,
        autoCropHeight: 360,
        centerBox: false,
        high: true,
        max: 99999,
      },
      fixed: false,
      fixedNumber: [4, 3],
      fileName: ''
    };
  },
  mounted() {
  },
  methods: {
    realTime(data) {
      this.previews = data
    },
    imgLoad(msg) {
      console.log(msg)
    },
    cropMoving(data) {
      console.log(data, '截图框当前坐标')
    },
    initData(data){
      this.fileName = data.name
      this.closeFlag = true
      this.imageToBase64(data)
    },
    finish() {
      let _this = this
      this.$refs.cropper.getCropData(data => {
        _this.$emit('getFile', _this.dataURLtoBlob(data))
        _this.closeModel()
      })
    },
    imageToBase64(file) {
      let reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = (e) => {
        let data
        if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.option.img = data
      }
      reader.onerror = function(error) {
        console.log('Error: ', error)
      }
    },
    dataURLtoBlob(dataurl) {
      let _this = this,
        arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], _this.fileName, {
        type: mime
      });
    },
    closeModel() {
      this.closeFlag = false
    }
  }
};
</script>
<style scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  .green ::v-deep .el-timeline-item__content,
  .green ::v-deep .el-timeline-item__timestamp{
    color: green
  }
  .cut {
    width: 650px;
    height: 500px;
    margin: 10px auto;
  }
</style>

