<template>
  <div class="all-container">
    <div class="my-head">
      <div class="head-hasbg">
        <div class="top-cont">
          <div class="login-title">
            <div class="login-logo">
              <img src="~@/assets/images/herad-logo.png" alt="">
            </div>
            <div class="login-tilt-cont">
              <p class="login-title1">中国农产品质量安全</p>
              <p class="login-title2">承诺达标合格证追溯平台</p>
            </div>
          </div>
          <div class="top-cont-userinfo" v-if="contObj.bodyId">
            <div class="top-cont-comname">
              <p class="tcc-1">{{ contObj.companyName }}</p>
              <p class="tcc-2">账号：{{ contObj.account }}</p>
            </div>
            <div class="top-cont-logout">
              <img @click="logout" src="~@/assets/images/herad-icon.png" alt="">
            </div>
          </div>
          <div class="top-cont-userinfo un-login" v-else @click="linkTologin">暂未登录</div>
        </div>
        <div>
          <top-nav ref="topNav" @getList="getList"></top-nav>
        </div>
      </div>
      <!-- <div class="top-menus">
        <div class="top-top">
          <top-nav ref="topNav" @getList="getList"></top-nav>
        </div>
          
        <div class="top-cont-userinfo" v-if="contObj.bodyId">
          <div class="top-cont-comname">
            <p>{{ contObj.companyName }}  </p>
            <p>账号：{{ contObj.account }}（{{ contObj.userName }}）</p>
          </div>
          <div class="top-cont-logout" @click="logout">退出账号</div>
        </div>
        <div class="top-cont-userinfo un-login" v-else @click="linkTologin">暂未登录</div>
      </div> -->
      <div>
        <div class="top-tabs" id="topTaps">
          <span v-for="(item, i) in tagList" 
            :key="i" 
            :class="'tags-view-item' + ( item.id == cutTagId ? ' active' : '') "
            @click="likTopage(item, i)"
          >
            {{ item.name }}
            <span v-if="!(tagList.length == 1 && tagList[0].id == '1')" class="el-icon-close" @click.stop="delTag(i, item)"></span>
          </span>
        </div>
      </div>

    </div>
    <div :class="cutTagId == '1' ? 'real-content1' : 'real-content'">
      <div class="real-content-block">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import topNav from '@/components/topNav.vue'
export default {
  name: 'basic',
  data() {
    return {
      sysName: CONSTPARAMS.sysName,
      contObj: {}, 
      imgUrl: require('@/assets/images/login_icon.png'),
      tagList: [],
      cutTagId: ''
    }
  },
  components: {
    topNav
  },
  mounted() {
    this.contObj = {
      companyName: utils.getBodyName(),
      userName: utils.getUserInfo('fullName'),
      account: utils.getUserName(),
      bodyId: utils.getBodyId()
    }
  },
  methods: {
    getList(data){
      this.tagList = data.list || []
      this.cutTagId = data.curId || ''
      this.scrollLeft()
    },
    scrollLeft(){
      this.$nextTick(() => {
        let i = 0,
          list = this.tagList || []
        list.forEach((cur, index) => {
          if(cur.id == this.cutTagId){
            i = index
          }
        })
        let dom1 = document.querySelectorAll('.tags-view-item')[i],
        dom2 = document.querySelector('#topTaps')
        if(dom1 && dom2){
          let left = dom1.offsetLeft || 0
          dom2.scrollLeft = left
        }
      })
    },
    likTopage(item, i){
      this.$router.push({
        path: item.path
      })
      this.cutTagId = item.id || ''
      this.scrollLeft()
    },
    delTag(i, item){
      let id = this.cutTagId
      this.tagList.splice(i, 1)
      if(item.id == id){
        let j = i - 1
        if(j < 0 && this.tagList.length){
          j = 0
        }
        if(j < 0){
          this.$router.push({
            path: '/'
          })
          this.$refs.topNav.linkTohome()
        }else{
          this.cutTagId = this.tagList[j].id || ''
          this.$router.push({
            path: this.tagList[j].path
          })
          this.scrollLeft()
        }
      }
    },
    linkTologin(){
      this.$router.push({
        path: '/login'
      })
    },
    logout(){
      utils.removeStore('userInfo')
      this.message('退出成功！', "success")
      this.$router.push({
        path: '/login'
      })
    }
  },
  beforeUpdate() {},
  beforeMount() {
  }
}
</script>
<style lang="scss" scoped>
  .all-container{
    background-color: #f2f3f4;
    // min-width: 1000px;
  }
  .head-hasbg{
    background: url('~@/assets/images/head.png') center no-repeat #64aaff;
  }
  .login-title{
    display: flex;
    justify-content: space-between;
  }
  .login-logo{
    width: 50px;
    padding-right: 10px;
  }
  .login-logo img{
    width: 100%;
  }
  .login-title1{
    font-size: 12px;
    padding-bottom: 4px;
    letter-spacing: 1px;
  }
  .login-title2{
    font-size: 18px;
    letter-spacing: 2px;
  }
  .real-content1{
    padding: 0 10px 10px;
    box-sizing: border-box;
  }
  .real-content{
    height: calc(100vh - 146px);
    padding: 0 15px 15px;
    box-sizing: border-box;
  }
  .real-content-block{
    height: 100%;
  }
  .el-header{
    padding: 0;
  }
  .top-cont{
    color: #fff;
    padding: 10px 20px 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .top-cont-userinfo{
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    font-size: 14px;
    align-items: center;
  }
  .un-login{
    cursor: pointer;
    text-decoration: underline;
  }
  .top-menus{
    width: 100%;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: space-between;
  }
  .top-cont-logout{
    padding-left: 10px;
    cursor: pointer;
  }
  .top-cont-logout img{
    padding-top: 10px;
  }
  .top-top{
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .top-cont-title{
    margin: 10px;
    font-size: 16px;
  }
  .top-cont-comname{
    text-align: left;
    color: #000;
    font-weight: 700;
    padding-right: 10px;
  }
  .tcc-1{
    font-size: 15px;
    padding-bottom: 3px;
  }
  .tcc-2{
    font-size: 13px;
  }
  .home-icon{
    height: 40px;
    vertical-align: middle;
  }
  .top-tabs{
    height: 50px;
    width: 100%;
    background: #fff;
    // box-shadow: 0 2px 6px 0 rgbg(131 145 169, 0.12);
    position: relative;
    overflow: auto;
    white-space: nowrap;
  }
  .el-icon-close:hover{
    color: #fff;
    background-color: #3FA9F5;
  }
  .tags-view-item.active {
    color: #3FA9F5;
    border-color: #3FA9F5;
    font-weight: 700;
  }
  .tags-view-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 28px;
    line-height: 28px;
    border: 1px solid #cecece;
    color: #979797;
    background: #fff;
    padding: 0 8px;
    font-size: 12px;
    margin-left: 11px;
    margin-top: 11px;
    border-radius: 4px;
  }
</style>
