const depend = (param) => {
    switch (typeof param) {
        case 'boolean':
            param = param
            break
        case 'string':
            param = !!param.length
            break
        case 'function':
            param = param()
        default:
            param = !0
    }
    return param
}
export default {
    /**
     * 验证必填元素
     */
    required(value, param) {
        if (!depend(param)) {
            return 'dependency-mismatch'
        } else if (typeof value === 'number') {
            value = value.toString()
        } else if (typeof value === 'boolean') {
            return !0
        }
        return value.length > 0
    },
    /**
     * 验证电子邮箱格式
     */
    email(value) {
        return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value)
    },
    /**
     * 验证手机格式
     */
    phone(value) {
        return /^1[34578]\d{9}$/.test(value)
    },
    //支持+86
    tel(val){
        return /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(val)
    },
  //   tel(val){
  //     return /^((\+?86)|(\(\+86\)))?1\d{10}$/.test(val)
  // },
    /**
     * 验证URL格式
     */
    url(value) {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
    },
    /**
     * 验证日期格式
     */
    date(value) {
        return !/Invalid|NaN/.test(new Date(value).toString())
    },
    /**
     * 验证ISO类型的日期格式
     */
    dateISO(value) {
        return /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(value)
    },
    /**
     * 验证十进制数字
     */
    number(value) {
        return /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(value)
    },
    /**
     * 验证整数
     */
    digits(value) {
        return /^\d+$/.test(value)
    },
    /**
     * 验证身份证号码
     */
    idcard(value) {
        return /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(value)
    },
    /**
     * 验证两个输入框的内容是否相同
     */
    equalTo(value, param) {
        return value === that.data[param]
    },
    /**
     * 验证是否包含某个值
     */
    contains(value, param) {
        return value.indexOf(param) >= 0
    },
    /**
     * 验证最小长度
     */
    minlength(value, param) {
        return value.length >= param
    },
    /**
     * 验证最大长度
     */
    maxlength(value, param) {
        return value.length <= param
    },
    /**
     * 验证一个长度范围[min, max]
     */
    rangelength(value, param) {
        return (value.length >= param[0] && value.length <= param[1])
    },
    /**
     * 验证最小值
     */
    min(value, param) {
        return value >= param
    },
    /**
     * 验证最大值
     */
    max(value, param) {
        return value <= param
    },
    /**
     * 验证一个值范围[min, max]
     */
    range(value, param) {
        return (value >= param[0] && value <= param[1])
    },
}