import { httpMethods } from './$http'

export default {
  psccpage(params) { //开具纸质合格证（打印）列表
		return httpMethods.get('csc-certificate-print/hgz/print/syscertificateofcompliance/page/pc', params)
  },

  psccdelete(id) { //开具纸质合格证（打印）- 删除
		return httpMethods.del('csc-certificate-print/hgz/print/syscertificateofcompliance/delete/' + id)
  },

  psccadd(params) { //新增
		return httpMethods.postJson('csc-certificate-print/hgz/print/syscertificateofcompliance', params)
  },
  
  psccmodify(params) { //修改
		return httpMethods.put('csc-certificate-print/hgz/print/syscertificateofcompliance', params)
	},

  psccgetInfo(id) { //合格证打印-详情
		return httpMethods.get('csc-certificate-print/hgz/print/syscertificateofcompliance/getInfo/' + id)
  },

  //合格证-激活
  psgetUserAuthenticationEntity(params) { //电子印章验证
		return httpMethods.get('csc-certificate-activate/hgz/activate/syscertificateofcompliance/getUserAuthenticationEntity', params)
  },

  asccpage(params) { //列表
		return httpMethods.get('csc-certificate-activate/hgz/activate/syscertificateofcompliance/page/pc', params)
	},

	// asccpage(params) { //列表
	// 	return httpMethods.get('csc-certificate-activate/hgz/activate/syscertificateofcompliance/page/xcx', params)
	// },

	asccadd(params) { //新增
		return httpMethods.postJson('csc-certificate-activate/hgz/activate/syscertificateofcompliance', params)
  },
  
  asccmodify(params) { //修改
		return httpMethods.postJson('csc-certificate-activate/hgz/activate/syscertificateofcompliance', params)
	},

	asccdelete(params) { //删除
		return httpMethods.del('csc-certificate-activate/hgz/activate/syscertificateofcompliance/delete/', params)
	},

	asccgetInfo(params) { //详情
		return httpMethods.get('csc-certificate-activate/hgz/activate/syscertificateofcompliance/getInfo/', params)
	},

  psccscanQRCodes(params) { //扫码
		return httpMethods.get('csc-certificate-activate/hgz/activate/syscertificateofcompliance/scanQRCodes', params)
  },

	asccactivePage(params) { //激活记录分页查询
		return httpMethods.postJson('csc-certificate-activate/hgz/activate/syscertificateofcomplianceprintactivate/page', params)
	},

	asccdeactive(params) { //作废/激活
		return httpMethods.put('csc-certificate-activate/hgz/activate/syscertificateofcomplianceprintactivate/cancellationOrActivate', params)
	},
  
  chasgetInfo(id) { //合格证激活-详情
		return httpMethods.get('csc-certificate-activate/hgz/activate/syscertificateofcompliance/getInfo/' + id)
	},

	psccprintAdd(params) { //打印新增
		return httpMethods.postJson('csc-certificate-print/hgz/print/syscertificateofcomplianceprintactivate', params)
  },

  psccgetId(params) { //获取ID
		return httpMethods.get('csc-certificate-print/hgz/print/syscertificateofcompliance/getCertificateOfComplianceId', params)
  },

  commondict(params) { //基础组件数据字典数据
		return httpMethods.get('admin/dict/tree', params)
  },

  savequalificationcertificate(params) { //新增主体资质证书
		return httpMethods.postJson('archive-produce/savequalificationcertificate', params)
  },

  updatequalification(params) { //修改资质证书
		return httpMethods.put('archive-produce/qualification/update', params)
  },
  apfindqualification(params) { //查询资质证书详细信息
		return httpMethods.get('archive-produce/qualification/find', params)
  },

  psfindqualification(params) { //查看主体资质证书
		return httpMethods.get('archive-produce/findqualification', params)
  },

  deletequalification(params) { //删除资质证书
		return httpMethods.del('archive-produce/qualification/delete', params)
  },

  uploadFiles(params, uploadVideoProcess) { //上传文件
		return httpMethods.uploadGetFiles('admin/upload', params, uploadVideoProcess)
  },

  uploadFiles2(params) { //上传文件
		return httpMethods.uploadGetFiles('sys/card/auth', params)
  },

  commoncphgzprint(params) { //合格证打印和激活记录查询
		return httpMethods.get('csc-certificate-print/hgz/print/syscertificateofcomplianceprintactivate/miniProgramPage', params)
  },

  commoncphgzgetHGZUseStatistics(params) { //合格证使用统计查询
		return httpMethods.get('csc-certificate-print/hgz/print/syscertificateofcomplianceprintactivate/getHGZUseStatistics', params)
  },

  commonproducttypetree(params) { //产品类型模块-获取产品类型数据默认的接口
		return httpMethods.get('admin/producttype/getTreeByNameOrAliasName', params)
	},

  findinputsuse(params) { //分页查询投入品使用记录
		return httpMethods.get('archive-produce/inputs/findinputsuse', params)
  },

  findinputsrepertory(params) { //分页查询投入品剩余库存
		return httpMethods.get('archive-produce/inputs/findinputsrepertory', params)
  },

  pssfindallinputs(params) { //分页查找投入品
		return httpMethods.get('archive-produce/findallinputs', params)
  },

  psdeleteinput(params) { //删除投入品
		return httpMethods.del('archive-produce/deleteinput', params)
  },

  pssaveinput(params) { //新增投入品
		return httpMethods.postJson('archive-produce/saveinput', params)
  },

  psupdateinput(params) { //修改投入品
		return httpMethods.put('archive-produce/updateinput', params)
  },

  psfindregistercode(params) { //通过登记证号返回数据
		return httpMethods.get('archive-produce/findregistercode', params)
  },

  findinput(params) { //查询投入品详细信息
		return httpMethods.get('archive-produce/findinput', params)
  },

  commonsupplierpage(params, type) { //供应商列表
		return httpMethods.getHead('admin/supplier/page', params, {
      'supplier-type': type
    })
  },

  commonsupplierDetail(supplierId, type) { //供应商详情
		return httpMethods.getHead(`admin/supplier/${supplierId}`, undefined, {
      'supplier-type': type
    })
  },

  commondeletesupplier(params, type = '') { //删除供应商
		return httpMethods.delHead('admin/supplier/delete', params, {
      'supplier-type': type
    })
  },

  commonsuppliersave(params, type = '') { //添加供应商
		return httpMethods.postJsonHead('admin/supplier/save', params, {
      'supplier-type': type
    })
  },

  commonsupplierupdate(params, type = '') { //更新供应商
		return httpMethods.putHead('admin/supplier/update', params, {
      'supplier-type': type
    })
  },

  commonbodysave(params) { //主体业务-新增主体（临时主体）
		return httpMethods.postJson('admin/body/save', params)
  },
  
  commonbodyupdate(params) { //修改主体
		return httpMethods.put('admin/body/update', params)
	},

  commonproductDetail(bodyId) { //主体产品-主体详情
		return httpMethods.get(`admin/body/${bodyId}`)
	},

	commonbodypage(params, type = '') { //主体业务-主体列表查询
		return httpMethods.getHead('admin/body/page', params, {
      'supplier-type': type
    })
	},

  commoncustomerpage(params) { //客户列表
		return httpMethods.get('admin/customer/page', params)
  },

  commoncustomerDetail(customerId) { //看详情
		return httpMethods.get(`admin/customer/${customerId}`)
  },

  commoncustomersave(params) { //添加客户
		return httpMethods.postJson('admin/customer/save', params)
  },

  commoncustomerupdate(params) { //修改客户
		return httpMethods.put('admin/customer/update', params)
  },

  commondeletecustomer(params) { //删除客户
		return httpMethods.delJson('admin/customer/delete', params)
  },

  commonproductpage(params) { //主体产品-主体产品列表
		return httpMethods.get('admin/product/page', params)
  },

  commonproductcode(params) { //生成产品编码
		return httpMethods.get('admin/product/code', params)
  },

  commongetproductId(productId, requestType) {  //根据产品id查询产品详情
		return httpMethods.getHead(`admin/product/${productId}`, undefined, {
      'requestType': requestType
    })
  },

	commonDelProduct(params) { //删除产品
		return httpMethods.delJson('admin/product/delete', params)
  },

  commonunitqualificationfilelist(params) { //获取产品认证接口
		return httpMethods.get('admin/unitqualificationfile/list', params)
  },

  commonproductsave(params) { //主体产品-添加主体产品
		return httpMethods.postJson('admin/product/save', params)
  },

  commonproductupdate(params) { //根据产品id修改产品信息
		return httpMethods.put('admin/product/update', params)
	},

  commontransactionrecordpage(params) { //收购来源列表
		return httpMethods.get('admin/transactionrecord/page', params)
  },

  commoncertificateofcompliancepage(params) { //合格证列表
		return httpMethods.get('admin/certificateofcompliance/page', params)
	},

  findallproductbatch(params) { //分页查询产品档案
		return httpMethods.get('archive-produce/findallproductbatch', params)
  },

  deleteproductbatch(params) { //删除产品档案
		return httpMethods.del('archive-produce/deleteproductbatch', params)
  },

  findproductbatch(params) { //查询产品详细信息
		return httpMethods.get('archive-produce/findproductbatch', params)
  },

  saveproductbatch(params) { //新增产品档案
		return httpMethods.postJson('archive-produce/saveproductbatch', params)
  },

  updateproductbatch(params) { //修改产品档案
		return httpMethods.put('archive-produce/updateproductbatch', params)
  },

  findbaseplot(params) { //主体查基地与地块
		return httpMethods.get('archive-produce/common/findbaseplot', params)
  },

  apfindproductbatch(params) { //查询产品档案详细信息
		return httpMethods.get('archive-produce/findproductbatch', params)
  },

  commonsignin(params) { //账号登录
		return httpMethods.postJson('auth/signin', params)
  },
  
  commonsignmobile(params) { //手机登录
		return httpMethods.postJson('auth/signin/mobile', params)
	},

  getToken(params) { //手机登录获取token
		return httpMethods.postJson('auth/signin/mobile/select', params)
	},

  commonareastree(areaCode) { //根据父节点areaCode展示区域树
		return httpMethods.get('admin/areas/list', {
      'areaCode': areaCode
    })
  },

  commonuserinfo(params) { //用户模块-通过userName获取用户详情
		return httpMethods.get('admin/user/info', params)
	},

  psfindlabelorder(params) { //查询用户主体下所有订单
		return httpMethods.get('conformity-label/findlabelorder', params)
  },
  
	psfinddict(params) {  //打开领取购买合格证标签页面获取数据
		return httpMethods.get('conformity-label/finddict', params)
  },

	psfindorderdetail(params) {  //打开领取购买合格证标签页面获取数据
		return httpMethods.get('conformity-label/findorderdetail', params)
  },

	psdeleteLabelOrder(params) {  //逻辑删除合格证标签订单即关联订单详情信息
		return httpMethods.del('conformity-label/deleteLabelOrder', params)
  },
  
  psspurchase(params) { //电子合格证标签免费领取与购买
		return httpMethods.postJson('conformity-label/purchase', params)
  },

  psupdatelaberorder(params) { //修改未支付订单数据
		return httpMethods.put('conformity-label/updatelaberorder', params)
  },

  commonsend(params) { //发送验证码
		return httpMethods.postJson('sys/sms/send', params)
  },

  commoncheck(params) { //校验验证码是否有效
		return httpMethods.postJson('sys/sms/check', params)
  },

  pspurchasesell(params) { //我要销售业务，查询订单
		return httpMethods.get('purchase-sell/findsellorder', params)
  },
  
  pspurchaseselldeleteorder(params) { //采购 删除交易记录
		return httpMethods.del(`purchase-sell/purchasedeleteorder?consigneeId=${params.consigneeId}&transactionRecordId=${params.transactionRecordId}`)
  },
  
  pspurchasesellsellupdatestatus(params) { //销售，确认或取消订单
		return httpMethods.put('purchase-sell/sellupdatestatus', params)
  },

  commonsavesellorder(params) { //销售新增
		return httpMethods.postJson('purchase-sell/savesellorder', params)
  },

  psfindresidualweight(params) { //查询合格证剩余重量
		return httpMethods.get('purchase-sell/findresidualweight', params)
  },

  findsellprice(params) { //销售页面查询合格证销售价格
		return httpMethods.get('purchase-sell/sell/findsellprice', params)
  },

  psfindpurchaseorder(params) { //我要采购，查询订单
		return httpMethods.get('purchase-sell/findpurchaseorder', params)
	},

  commonpcselldeleteorder(params) { //销售 删除交易记录
		return httpMethods.del(`purchase-sell/selldeleteorder?supplierId=${params.supplierId}&transactionRecordId=${params.transactionRecordId}`)
  },

  commonpspurchaseupdatestatus(params) { //我要采购，确认或取消订单
		return httpMethods.put('purchase-sell/purchaseupdatestatus', params)
  },

  purchasefind(params) { //采购查询交易记录详细信息
		return httpMethods.get('purchase-sell/purchase/find', params)
  },

  sellfind(params) { //销售查询交易记录详细信息
		return httpMethods.get('purchase-sell/sell/find', params)
  },

  psccgetHistoryBaseName(id) { //查询历史基地名称
		return httpMethods.get('csc-certificate-print/hgz/print/syscertificateofcompliance/getHistoryBaseName/' + id)
  },

  psccgetProductBatch(bodyId, productId) { //查询产品生产批次
		return httpMethods.get(`csc-certificate-print/hgz/print/syscertificateofcompliance/getProductBatch/${bodyId}/${productId}`)
	},

  psccgetHistoryFile(bodyId, productId) { //查询历史文件
		return httpMethods.get(`csc-certificate-print/hgz/print/syscertificateofcompliance/getHistoryFile/${bodyId}/${productId}`)
  },

  commonmanualsavepurchaseorder(params) { //收购手动新增
		return httpMethods.postJson('purchase-sell/manualsavepurchaseorder', params)
  },

  commonsavepurchaseorder(params) { //收购非母码新增
		return httpMethods.postJson('purchase-sell/savepurchaseorder', params)
  },

  commonsavebatch(params) { //收购母码新增
		return httpMethods.postJson('purchase-sell/purchase/savebatch', params)
  },

  psfindcertificate(params) { //收购新增扫码获取数据
		return httpMethods.get('purchase-sell/findcertificate', params)
  },

  findalldrugsutilization(params) { //分页查询药品使用
		return httpMethods.get('archive-produce/drugsutilization/findalldrugsutilization', params)
  },

  deletedrugsutilization(params) { //删除药品使用记录
		return httpMethods.del('archive-produce/drugsutilization/deletedrugsutilization', params)
  },

  savedrugsutilization(params) { //新增药品使用记录
		return httpMethods.postJson('archive-produce/drugsutilization/savedrugsutilization', params)
  },

  updatedrugsutilization(params) { //修改药品使用记录
		return httpMethods.put('archive-produce/drugsutilization/updatedrugsutilization', params)
  },

  finddrugsutilization(params) { //查询药品使用记录信息
		return httpMethods.get('archive-produce/drugsutilization/finddrugsutilization', params)
  },

  psfinddata(params) { //跳转新增药品获取下拉框数据
		return httpMethods.get('archive-produce/drugsutilization/finddata', params)
  },

  apfinddata(params) { //跳转新增肥料获取下拉框数据
		return httpMethods.get('archive-produce/fodderuse/finddata', params)
  },

  findfodderuserecord(params) { //查询肥料/饲料使用记录
		return httpMethods.get('archive-produce/fodderuse/findfodderuserecord', params)
  },

  deletefodderuserecord(params) { //删除肥料/饲料使用记录
		return httpMethods.del('archive-produce/fodderuse/deletefodderuserecord', params)
  },

  savefodderuserecord(params) { //新增肥料/饲料使用记录
		return httpMethods.postJson('archive-produce/fodderuse/savefodderuserecord', params)
  },

  updatefodderuserecord(params) { //修改肥料/饲料使用记录
		return httpMethods.put('archive-produce/fodderuse/updatefodderuserecord', params)
  },

  findallfodderuserecord(params) { //分页查询肥料/饲料使用记录
		return httpMethods.get('archive-produce/fodderuse/findallfodderuserecord', params)
  },

  findallproductioncheckrecord(params) { //分页查询检验检测记录
		return httpMethods.get('archive-produce/productioncheck/findallproductioncheckrecord', params)
  },

  deleteproductioncheckrecord(params) { //删除检验检测记录
		return httpMethods.del('archive-produce/productioncheck/deleteproductioncheckrecord', params)
  },

  findproductioncheckrecord(params) { //查询检验检测记录信息
		return httpMethods.get('archive-produce/productioncheck/findproductioncheckrecord', params)
  },

  saveproductioncheckrecord(params) { //新增检验检测记录
		return httpMethods.postJson('archive-produce/productioncheck/saveproductioncheckrecord', params)
  },

  updateproductioncheckrecord(params) { //修改检验检测记录
		return httpMethods.put('archive-produce/productioncheck/updateproductioncheckrecord', params)
  },

  findallharvestrecord(params) { //分页查询收获记录
		return httpMethods.get('archive-produce/harvest/findallharvestrecord', params)
  },

  deleteharvestrecord(params) { //删除收获记录
		return httpMethods.del('archive-produce/harvest/deleteharvestrecord', params)
  },

  findharvestRecord(params) { //查询收获记录详情
		return httpMethods.get('archive-produce/harvest/findharvestRecord', params)
  },

  saveharvestrecord(params) { //新增收获记录
		return httpMethods.postJson('archive-produce/harvest/saveharvestrecord', params)
  },

  updateharvestrecord(params) { //修改收获记录
		return httpMethods.put('archive-produce/harvest/updateharvestrecord', params)
  },

  findallfarmingmanagement(params) { //分页查询农事记录
		return httpMethods.get('archive-produce/farmingmanagement/findallfarmingmanagement', params)
  },

  deletefarmingmanagement(params) { //删除农事记录
		return httpMethods.del('archive-produce/farmingmanagement/deletefarmingmanagement', params)
  },

  findfarmingmanagement(params) { //查询农事记录
		return httpMethods.get('archive-produce/farmingmanagement/findfarmingmanagement', params)
  },

  savefarmingmanagement(params) { //新增农事记录
		return httpMethods.postJson('archive-produce/farmingmanagement/savefarmingmanagement', params)
  },

  updatefarmingmanagement(params) { //修改农事记录
		return httpMethods.put('archive-produce/farmingmanagement/updatefarmingmanagement', params)
  },

  pssdeletebaseinfo(params) { //删除基地
		return httpMethods.del('archive-produce/deletebaseinfo', params)
  },

  psfindbaseinfo(params) { //基地详细信息查询
		return httpMethods.get('archive-produce/findbaseinfodetailed', params)
  },

  pssfindallbaseinfo(params) { //基地信息分页查询
		return httpMethods.get('archive-produce/findallbaseinfo', params)
  },

  pssavebaseinfo(params) { //新增基地
		return httpMethods.postJson('archive-produce/savebaseinfo', params)
  },

  psupdatebaseinfo(params) { //修改基地
		return httpMethods.put('archive-produce/updatebaseinfo', params)
  },

  pssfindallplot(params) { //分页查询地块信息
		return httpMethods.get('archive-produce/findallplot', params)
  },
  
	psdeleteplot(params) { //删除块地信息
		return httpMethods.del('archive-produce/deleteplot', params)
  },

  psfindplotdetailed(params) { //查询地块详细信息
		return httpMethods.get('archive-produce/findplotdetailed', params)
  },

  pssaveplot(params) { //新增块地信息
		return httpMethods.postJson('archive-produce/saveplot', params)
  },

  psupdateplot(params) { //修改块地信息
		return httpMethods.put('archive-produce/updateplot', params)
  },

  saveuser(params) { //用户注册
		return httpMethods.postJson('enroll/saveuser', params)
  },

  feedbacksave(params) { //新增问题反馈
		return httpMethods.postJson('admin/feedback/save', params)
	},

  commongetBodyInfoByUserAccount(params) {  //获取主体信息
		return httpMethods.get('admin/body/getBodyInfoByUserAccount', params)
  },
  
	commongetUserAccount(params) { //找回账号密码
		return httpMethods.get('admin/user/getUserAccount', params)
  },

  psfindmainbody(params) { //查看主体信息
		return httpMethods.get('archive-produce/findmainbody', params)
  },

  psupdatemainbody(params) { //修改主体信息
		return httpMethods.put('archive-produce/updatemainbody', params)
  },

  findlistofdelivery(params) { //条件查询配送清单
		return httpMethods.get('purchase-sell/delivery/findlistofdelivery', params)
  },

  deliveryDel(params) { //配送清单删除选中订单
		return httpMethods.delJson('purchase-sell/delivery/delete', params)
  },

  addPrint(params) { //新增打印记录
		return httpMethods.postJson('purchase-sell/delivery/print', params)
  },

  deliveryPrint(params) { //打印订单参数整理
		return httpMethods.get('purchase-sell/delivery/preview', params)
  },

  deliveryupdate(params) { //配送清单修改订单重量与单价
		return httpMethods.put('purchase-sell/delivery/update', params)
  },

  deliveryImport(params) { //excel导入数据
		return httpMethods.uploadGetFiles('purchase-sell/delivery/import', params)
  },

  deliveryexport(params) { //导出数据到excel
		return httpMethods.getFile('purchase-sell/delivery/export/excel', params)
  },
  deliveryexport2() { //导出数据到excel
		return httpMethods.getFile('purchase-sell/delivery/export')
  },

  changePW(params) { //修改密码
		return httpMethods.put('auth/pupdate', params)
  },

  wechatunBind(params) { //取消绑定微信
		return httpMethods.putHead('auth/wechat/unBind', params, {
      'requestType': 'pc'
    })
  },

  getmyInfo(params) { //根据用户id查询基础信息
		return httpMethods.get('admin/user/myInfo', params)
  },

  updateMyInfo(params) { //修改我的信息
		return httpMethods.put('admin/user/updateMyInfo', params)
  },

  trackingproductfile(params) { //微信扫码追溯商品信息
		return httpMethods.get('archive-produce/tracking/trackingproductfile', params)
  },

  findcscimageurl(params) { //获取合格证图片
		return httpMethods.get('archive-produce/tracking/findcscimageurl', params)
  },

  getByAddress(params) { //根据定位查询对应区域编码
		return httpMethods.get('admin/areas/getByAddress', params)
  },

  accountlist(params) { //查询当前主体非管理员账号
		return httpMethods.get('admin/user/account/list', params)
  },

  saveaccount(params) { //添加账号
		return httpMethods.postJson('admin/user/account/save', params)
  },

  updateaccount(params, type) { //修改账号（状态、密码）
		return httpMethods.putHead('admin/user/account/update', params, {
      'account-type': type || 'pass'
    })
  },

  invoicelist(params) { //已支付未开发票订单
		return httpMethods.get('admin/electroniclabelorder/page', params)
  },

  invoicesave(params) { //申请发票
		return httpMethods.postJson('admin/invoice/save', params)
  },

  prepayment(params) { //微信扫码预支付
		return httpMethods.postJson('conformity-label/scan/prepayment', params)
  },

  findpayment(params) { //微信订单查询，商品订单查询
		return httpMethods.get('conformity-label/wechat/findpayment', params)
  },

  paysuccess(electronicLabelOrderId) { //支付成功后修改支付状态
		return httpMethods.put('conformity-label/wechat/paysuccess?electronicLabelOrderId=' + electronicLabelOrderId)
  },

  salesflowarea(params) { //销售流向地域围范统计
		return httpMethods.get('certificate-statistics/salesflowarea/find/statistics', params)
  },

  salesdirectionstatistics(params) { //销售流向地域围范统计
		return httpMethods.get('certificate-statistics/salesdirectionstatistics/find/statistics', params)
  },

  certificatequantitystatistics(params) { //最近12月合格证使用统计
		return httpMethods.get('certificate-statistics/certificatequantitystatistics/find/statistics', params)
  },

  certificateweightstatistics(params) { //最近12月合格证使用重量统计
		return httpMethods.get('certificate-statistics/certificateweightstatistics/find/statistics', params)
  },

  annualsalesandpurchasestatistics(params) { //最近6年销售额和采购额环比
		return httpMethods.get('certificate-statistics/annualsalesandpurchasestatistics/find/statistics', params)
  },

  monthlysalesandpurchasestatistics(params) { //最近12月销售额和采购额统计
		return httpMethods.get('certificate-statistics/monthlysalesandpurchasestatistics/find/statistics', params)
  },

  logisticsfind(params) { //即时物流查询
		return httpMethods.get('conformity-label/logistics/find', params)
  },

  findwhethertosell(params) { //查询合格证是否进行销售操作
		return httpMethods.get('csc-certificate-print/hgz/print/syscertificateofcompliance/findwhethertosell', params)
  },

  pcPrint(params) { //PC打印获取打印图片
		return httpMethods.getFile('enroll/printer/pcPrint', params)
  },

  findSatisfaction(params) { //获取产品满意度星级
		return httpMethods.get('archive-produce/tracking/findSatisfaction', params)
  },

  trackingsatisfaction(params) { //追溯产品进行评价
		return httpMethods.postJson('archive-produce/tracking/satisfaction', params)
  },

  fileCode(id) { //获取指定文件
		return httpMethods.get(`/admin/file/code/${id}`)
  },
  
  getOperationDocument(params) { //获取操作手册文件
  		return httpMethods.get(`enroll/operationdocument/page`,params)
  },
}