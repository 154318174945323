<template>
  <el-dialog
    class="my-dialog1"
    title="上传视频"
    width="900px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    center
  >
    <div class="pics-list-cont">
      <div class="all-pics" v-for="(item, index) in imgUrls" :key="index">
        <video :src="item" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
        <label class="list-del" @click="handleRemove(index)"><i class="el-icon-close"></i></label>
      </div>
      <div class="upload-progress" v-if="showProgress">
        <el-progress type="circle"
          :percentage="videoUploadPercent"
          class="progress-style">
        </el-progress>
      </div>
      <div v-show="imgUrls.length < 9">
        <el-upload
          action
          :accept="'video/*'"
          :show-file-list="false"
          :http-request="uploadfiles"
          :limit="9"
          list-type="picture-card"
          class="pb10"
          :on-progress="uploadVideoProcess"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
    </div>
    <div class="forms-btns">
      <el-button type="primary" size="small" @click="picsSave" >确定</el-button >
      <el-button @click="closeModel" size="small">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
export default {
  name: "vids",
  components: {
  },
  props: {
    dictCode: {
      type: String,
      default: "",
    },
    businessCode: {
      type: String,
      default: "",
    },
    propName: {
      type: String,
      default: '',
    },
    storeTableName: {
      type: String,
      default: '',
    },
    imgSize: {
      type: Number,
      default: 0.2
    },
    videoSize: {
      type: Number,
      default: 5
    },
    //是否裁剪
    showcrop: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {   
      closeFlag: false,
      imgUrls: [],
      tableName: '',
      videoUploadPercent: 0,
      showProgress: false,
      showCount: 1
    }
  },
  created() {
	},
  methods: {
    handleRemove(index) {
      this.imgUrls.splice(index, 1)
    },
    async uploadfiles(param){
      if(this.imgUrls?.length >= 9){
        this.message('最多上传9个视频!', "warning")
        return
      }
      let formData = new FormData(),
        _this = this,
        file = param.file, 
        size = file.size / (1024 * 1024)
      
      if(size > this.videoSize){
        _this.message(`上传的文件不能超过${_this.videoSize}M,请处理文件大小后重新上传！`, "warning")
        return
      }
      this.videoUploadPercent = 0
      formData.append("dictCode", _this.dictCode)
      formData.append("businessCode", _this.businessCode)
      formData.append("files", file)
      _this.showProgress = true
      request.uploadFiles(formData, _this.uploadVideoProcess).then(res => {
        if(res && res.code == 200){
          _this.imgUrls.push(res.data[0].fileUrl)
          _this.tableName = res.data[0].tableName
        }else if(res){
          _this.message(res.msg || '', "error")
        }else{
          _this.message('上传失败，请稍后再试', "error")
        }
        _this.showProgress = false;
        _this.videoUploadPercent = 0
      }).catch(e => {
        _this.showProgress = false;
        _this.videoUploadPercent = 0
      })
    },
    initData(imgUrls){
      // if(this.showCount){
      //   this.imgUrls = [ ...imgUrls ]
      //   this.showCount = 0
      // }
      this.imgUrls = [ ...imgUrls ]
      this.closeFlag = true
    },
    picsSave(){
      let obj = {
        pics: [ ...this.imgUrls ],
        tableName: this.tableName,
        propName: this.propName,
        storeTableName: this.storeTableName
      }
      this.$emit("getpics", obj)
      this.closeModel()
    },
    uploadVideoProcess(progressEvent) {   
      let per = Number(
        ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2)
      )
      if(per == 100){
        this.videoUploadPercent = 95
        setTimeout(() => {
          this.videoUploadPercent = 100
        }, 600);
      }else{

        this.videoUploadPercent = per
      }
    },
    closeModel() {
      this.closeFlag = false
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
  .my-upload ::v-deep .el-upload--picture-card{
    width: 88px;
    height: 88px;
    line-height: 98px;
  }
  .my-dialog1 ::v-deep .el-dialog {
    margin-top: 15vh !important;
  }
  .pics-list-cont{
    display: flex;
    flex-wrap: wrap;
  }
  .all-pics{
    position: relative;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 200px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
  .all-pics .el-image{
    width: 100%;
    height: 100%;
  }
  .list-del{
    position: absolute;
    right: -17px;
    top: -7px;
    width: 46px;
    height: 26px;
    background-color: red;
    text-align: center;
    transform: rotate(45deg);
    box-shadow: 0 1px 1px #ccc;
    cursor: pointer;
  }
  .list-del i{
    font-size: 12px;
    margin-top: 12px;
    transform: rotate(-45deg);
    color: #fff;
  }
  .my-dialog1 .all-pics:hover .list-shadow{
    opacity: 1
  }
  .my-dialog1 .all-pics:hover .list-shadow span{
    display: inline-flex;
  }
  .my-dialog1 .list-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0,0,0,.5);
    transition: opacity .3s;
    line-height: 148px;
  }
  .my-dialog1 .list-shadow span {
    display: none;
    cursor: pointer;
    margin: 10px;
  }
  .my-dialog1 .list-shadow .list-delete {
    position: static;
    font-size: inherit;
    color: inherit;
  }
  .avatar {
    width: 100%;
    height: 148px;
    display: block;
  }
  .upload-progress{
    width: 200px;
    height: 148px;
    background-color: #fff;
    border: 1px solid #c0ccda;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .progress-style{
    margin-top: 7px
  }
</style>


