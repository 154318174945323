import CONSTPARAMS from "./const";
import request from './request'
import baseValidate from './baseValidate'
import { Message } from 'element-ui'
// import QrCode from 'qrcode-decoder'
import QrCode from './../../node_modules/qrcode-decoder/dist/index'


function httpPost(url,params) {
  var formEltTemp = document.createElement("form"); //增加表单，隐藏方式
  formEltTemp.action = url; 
  formEltTemp.method = "post";
  formEltTemp.style.display = "none"; //隐藏这个form

  //通过for..in来遍历params这个json数组对象
  for(var key in params){  
  var opt = document.createElement("textarea"); //新建一个文本框元素
  opt.name = key; //设置文本框的name属性
  opt.value = params[key]; //设置文本框的value属性
  formEltTemp.appendChild(opt); //把opt这个文本框加入到form表单中
  }

  document.body.appendChild(formEltTemp);//把增加的这个form表单添加到body中
  formEltTemp.submit(); //提交表单
  return formEltTemp; 
}

//获取年
export const formatYear = (data) => {
  return dateFormat(data, "yyyy");
}

//把const.js里面的常量转变为下拉框的值
export const constToSelectval = (name) => {
  let res = {};
  for (const key in CONSTPARAMS) {
    if (CONSTPARAMS.hasOwnProperty(key)) {
      const subObj = CONSTPARAMS[key];
      let subRes = [];
      for (const subKey in subObj) {
        if (subObj.hasOwnProperty(subKey)) {
          subRes.push({ label: `${subObj[subKey]}`, value: subKey });
        }
      }
      res[key] = subRes;
    }
  }
  return name ? res[name] : res;
}

//确认框弹出信息 - 删除
export const confirmDelMes = (mes, others) => {
  others = others || "删除";
  return `确定要${others}${mes}的记录吗？`;
}
//日期加+1
export const addDate=(date, days)=>{
  var d = new Date(date);
  d.setDate(d.getDate() + days);
  var m = d.getMonth() + 1;
  var da = d.getDate();
  if (m < 10) {
    m = '0' + m;
  }
  if (da < 10) {
    da = '0' + da;
  }
  return d.getFullYear() + '-' + m + '-' + da;
}

const formatTime = date => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  return `${[year, month, day].map(formatNumber).join('/')} ${[hour, minute, second].map(formatNumber).join(':')}`
}

const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : `0${n}`
}

const dateFormat = (date, format) => {
  if (!date) return "";
  if (!format) format = "yyyy-MM-dd HH:mm:ss";
  let type = typeof date;
  if (type === "string") {
    date = new Date(date.replace(/-/g, "/"));
  } else if (type === "number") {
    date = new Date(date);
  }
  if (!date instanceof Date) return;
  let dict = {
    yyyy: date.getFullYear(),
    M: date.getMonth() + 1,
    d: date.getDate(),
    H: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
    MM: ("" + (date.getMonth() + 101)).substr(1),
    dd: ("" + (date.getDate() + 100)).substr(1),
    HH: ("" + (date.getHours() + 100)).substr(1),
    mm: ("" + (date.getMinutes() + 100)).substr(1),
    ss: ("" + (date.getSeconds() + 100)).substr(1),
  };
  return format.replace(/(yyyy|MM?|dd?|HH?|ss?|mm?)/g, (item) => dict[item]);
}

const getUrlParam = (name, url) => {
	url = url || window.location.href
  if(!url.startsWith(CONSTPARAMS.scanUrl)){
    Message.error({message: '不是有效的合格证二维码！'})
    return false
  }
  let regExp = new RegExp(`[?|&]${name}=([^&#]*)`, 'g');
  let val = regExp.exec(url)
	return val ? val[1] : '';
}

const getParamCode = (name, url) => {
	let obj = {
    procurementCode: '',
    startCode: '',
    endCode: ''
  }
  //子码是H开头的，母码是Y或D开头的。
  if(url){
    let val = getUrlParam(name, url)
    if(val){
      if(val.indexOf('_') > -1){
        let arr = val.split('_')
        if(arr.length == 2){
          obj['procurementCode'] = arr[1]
        }else if(arr.length == 3){
          obj = {
            procurementCode:  arr[0],
            startCode:  arr[1],
            endCode:  arr[2]
          }
        }
      }else{
        obj['procurementCode'] = val
      }
    }else{
      return false
    }
  }
  return obj
}

const addDays = (odate, days = 1) => {
  days = parseInt(days)
  let date = new Date(odate);
  date.setDate(date.getDate() + days);
  return dateFormat(date, 'yyyy-MM-dd')
}

const getDays = (n) => {
  const datenow = new Date();
  const dateend =
    datenow.getFullYear().toString() +
    '-' +
    (datenow.getMonth() + 1).toString() +
    '-' +
    datenow.getDate().toString();
  datenow.setMonth(datenow.getMonth() - n);
  let dyear = datenow.getFullYear();
  let dmonth = datenow.getMonth() + 1;
  dmonth = dmonth < 10 ? 0 + dmonth : dmonth;
  let dday = datenow.getDate();
  const datestart = dyear.toString() + '-' + dmonth.toString() + '-' + dday.toString()
  return {
    start: dateFormat(datestart, 'yyyy-MM-dd'),
    end: dateFormat(dateend, 'yyyy-MM-dd')
  }
}

const getdefaultAddress =  (flag = false) => {
  let allAddress = getStore('userInfo')?.bodyAreaInfo || {}, 
      myAddress = [
        {areaCode: allAddress.provinceCode, areaName: allAddress.province},
        {areaCode: allAddress.cityCode, areaName: allAddress.city},
        {areaCode: allAddress.countyCode, areaName: allAddress.county},
        {areaCode: allAddress.townCode, areaName: allAddress.town},
        {areaCode: allAddress.villageCode, areaName: allAddress.village}
      ],
      arr = [],
      val = ''
  if(flag){
    myAddress.forEach(cur => {
      if(cur.areaCode){
        val += cur.areaName
      }
    })
    return val
  }
  myAddress.forEach(cur => {
    if(cur.areaCode){
      arr.push(cur)
    }
  })
  return arr
}

const getAddressByObj =  (add, flag = false) => {
  let allAddress = add || {}, 
      myAddress = [
        {areaCode: allAddress.provinceCode, areaName: allAddress.province},
        {areaCode: allAddress.cityCode, areaName: allAddress.city},
        {areaCode: allAddress.countyCode, areaName: allAddress.county},
        {areaCode: allAddress.townCode, areaName: allAddress.town},
        {areaCode: allAddress.villageCode, areaName: allAddress.village}
      ],
      arr = [],
      val = ''
  if(flag){
    myAddress.forEach(cur => {
      if(cur.areaCode){
        val += cur.areaName
      }
    })
    return val
  }
  myAddress.forEach(cur => {
    if(cur.areaCode){
      arr.push(cur)
    }
  })
  return arr
}

const findAreaCode  = (areaCode) => {
  let flag = false,
    allAddress = getStore('allAddress') || {}
  if(areaCode){
    for(let item in allAddress){
      let curI = typeof allAddress[item] == 'string' ?  [] : allAddress[item]
      if(curI && curI.length){
        curI && curI.length && curI.forEach(sec => {
          if(cur.areaCode == sec.areaCode){
            cur.pId = sec.parentAreaId
            cur.id = sec.areaId
            cur.level = sec.level
            cur.selected = false
          }
        })
      }
    }
  }
  return flag
}

async function requestAddress(areaCode) {
// const requestAddress  = (areaCode) => {
  let addrs = getStore('allAddress')
  return new Promise((resolve, reject) => {
    return request.commonareastree(areaCode).then(res => {
      resolve(1)
      if(res.code == 200){
        if(res.data){
          let datas = {
            ...addrs,
            ...res.data
          }
          setStore('allAddress', datas)
          
        }
      }else{
        Message.error({message: res.msg || ''})
      }
    }).catch(e => {
      resolve(1)
      console.log(e)
    })
  })
}
// async function getAddresslists(addr){
const getAddresslist =  (addr) => {
  let address = addr || getdefaultAddress() || [],
      arr = [],
      allAddress = getStore('allAddress') || {},
      initPId = '000000'
  let lens = address.length
  if(lens){
    address.forEach((cur, index) => {
      for(let item in allAddress){
        let curI = typeof allAddress[item] == 'string' ?  [] : allAddress[item]
        if(curI && curI.length){
          curI && curI.length && curI.forEach(sec => {
            if(cur.areaCode == sec.areaCode){
              cur.pId = sec.parentAreaId
              cur.id = sec.areaId
              cur.level = sec.level
              cur.selected = false
            }
          })
        }
      }
    })
    address.forEach((item, index) => {
      arr.push({
        ...item,
        pCode: index ? address[index - 1].areaCode : item.pId
      })
    })
    let len = arr.length
    len && (arr[len - 1].selected = true)
    arr = arr.filter(cur => {
      return cur.pId
    })
  }
  if(address.length != arr.length){
    let leng = arr.length
    for(let i = leng, l = address.length; i < l; i++){
      arr.push(address[i])
    }
  }
  return arr
}

const getAddresByObj = (allAddress) => {
  let arr = [
    {areaCode: allAddress.provinceCode, areaName: allAddress.province},
    {areaCode: allAddress.cityCode, areaName: allAddress.city},
    {areaCode: allAddress.countyCode, areaName: allAddress.county},
    {areaCode: allAddress.townCode, areaName: allAddress.town},
    {areaCode: allAddress.villageCode, areaName: allAddress.village}
  ]
  arr.forEach((cur, index) => {
    if(!cur.areaCode){
      arr.splice(index, 1)
    }
  })
  return arr
}

//let _res_ = filterTree(value, data)
let _expandKeys_  = []
const filterTree = (val, tree, newArr = []) => {
  if (!(tree.length && val)) {  
    _expandKeys_ = defaultOpenKeyArr  
    return tree
  }
  for (let item of tree) {
    if (item.title.indexOf(val) > -1) { 
      newArr.push(item)  
      if (item.children) _expandKeys_.push(item.key)  
      continue  
    }
    if (item.children && item.children.length) { 
        _expandKeys_.push(item.key)  
        let subArr = filterTree(val, item.children) 
      if (subArr && subArr.length) {  
        let node = { ...item, children: subArr }  
        newArr.push(node)  
      }        
    }
  }
  return newArr
}

 // 调用腾讯地图定位
async function getLocation() {
  // let _this = 
  return new Promise((resolve, reject) => {
    return wx.getSetting({
      success(res) {
        // console.log('res是否开启授权', res)
        if (!res.authSetting['scope.userLocation']) { 
          wx.authorize({
            scope: 'scope.userLocation',  
            success() {
              wx.chooseLocation({
                success: res => {
                  resolve(res)
                },
                fail: res => {
                  // console.log('打开地图选择位置取消', res)
                }
              })
            },
            fail() {
              resolve(fetchAgainLocation())
            }
          })
        } else {
          wx.chooseLocation({
            success: res => {
              resolve(res)
            },
            fail: res => {
              // console.log('打开地图选择位置取消', res)
            }
          })
        }
      }
    })
  })
}

async function fetchAgainLocation() {
  return new Promise((resolve, reject) => {
    return wx.getSetting({
      success: (res) => {
        let statu = res.authSetting;
        if (!statu['scope.userLocation']) {
          wx.showModal({
            title: '是否授权当前位置',
            content: '需要获取您的地理位置，请确认授权，否则地图功能将无法使用',
            success: (tip) => {
              if (tip.confirm) {
                wx.openSetting({
                  success: (data) => {
                    if (data.authSetting["scope.userLocation"] === true) {
                      wx.chooseLocation({
                        success: res => {
                          resolve(res)
                        }
                      })
                    } else {
                      wx.showToast({
                        title: '授权失败',
                        icon: 'success',
                        duration: 1000
                      })
                    }
                  },
                  fail: () => {},
                  complete: () => {}
                });
              }
            }
          })
        }
      },
      fail: ()=>{},
      complete: ()=>{}
    })
  })
}

async function uploadFiles(filePaths = [], businessCode, dictCode){
  let paths = []
  if(filePaths.length){
    for(let i = 0, len = filePaths.length; i < len; i++){
      const resData = await uploadMedia(filePaths[i].tempFilePath, businessCode, dictCode)
      paths.push(resData.fileUrl)
    }
  }
  return paths
}

async function uploadMedia(path, businessCode, dictCode, url = '', name){
  return new Promise((resolve, reject) => {
    let formData = {}
    if(url){
      formData = {
        files: path
      }
    }else{
      formData = {
        files: path,
        dictCode: dictCode || '',
        businessCode: businessCode || ''
      }
    }
    return wx.uploadFile({
      url: url ?  `${CONSTPARAMS.domain}${url}` : `${CONSTPARAMS.domain}/admin/upload`,
      filePath: path, 
      name: name || 'files',
      header: {   
        "Content-Transfer-Encoding": "binary",
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "form-data"
      },
      formData: formData,
      success: function(resData) {
        if(resData.statusCode == 200){
          let res = resData.data ? JSON.parse(resData.data) : ''
          if(res && res.code == 200){
            if(url){
              resolve(res)
            }else{
              resolve(res.data[0])
            }
          }else{
            let state = resData.statusCode, cont = `${state}${CONSTPARAMS.statusObj[state]}`
            wx.showToast({
              icon: 'none',
              duration: 3000,
              title: cont || '接口异常，请稍后重新！'
            })
          }
        }else{
          wx.showToast({
            icon: 'none',
            duration: 3000,
            title: url ? '没成功识别到身份证信息，请重新上传清晰的身份证！' : '上传失败！'
          })
        }
      },
      fail: function(e) {
        console.log(e)
        wx.showToast({
          icon: 'error',
          duration: 5000,
          title: '上传失败！'
        })
      },
      complete: function() {}
    })
  })
}

const videoToArr = video => {
  let arr = []
  if(video){
    let vs = video.split(',') || []
    vs.forEach(cur => {
      arr.push({
        isVideo: true,
        url: cur
      })
    })
  }
  return arr
}

const arrToVideo = video => {
  let arr = []
  if(video.length){
    video.forEach(cur => {
      arr.push(cur.url)
    })
  }
  return arr.join(',') 
}

const checkDataValidates= (_this, isLoading = true) => {
  let rules = _this.data.rules,
    contObj = _this.data.contObj,
    flag = true
  console.log('contObj', contObj)
  for(let rulePro in rules){
    let ruleItem = rules[rulePro], ruleFlag = true, val = contObj[rulePro]
    _this.setData({
      [`errObj.${rulePro}`]: ''
    })
    if(ruleItem.required){
      if(!val){
        flag = false
        ruleFlag = false
        _this.setData({
          [`errObj.${rulePro}`]: ruleItem.message
        })
      }
      if(ruleFlag && ruleItem.validator){
        let tempFlag = true
        tempFlag = _this[ruleItem.validator](rulePro, val, ruleItem.required || false)
        if(!tempFlag) flag = tempFlag
      }
    }else{
      if(val && ruleItem.validator){
        let tempFlag = true
        tempFlag = _this[ruleItem.validator](rulePro, val, ruleItem.required || false)
        if(!tempFlag) flag = tempFlag
      }
    }
  }
  if(!flag){
    wx.showToast({
      title: '请先完善信息！',
      icon: 'none',
      duration: 5000
    })
  }
  if(flag && isLoading){
    wx.showLoading({
      title: '保存中...',
      mask: true
    })
  }
  return flag
}

const checkSingleData = (e, _this) => {
  let val = typeof e.detail == 'string' ? e.detail.trim() : e.detail
  const{ propname } = e.currentTarget.dataset, rule = _this.data.rules[propname]
  _this.setData({
    [`contObj.${propname}`]: val
  })
  if(rule){
    let required = rule?.required, validator = rule?.validator
    if(required){
      if(val){
        _this.setData({
          [`errObj.${propname}`]: ''
        })
      }else {
        _this.setData({
          [`errObj.${propname}`]: _this.data.rules[propname].message
        })
      }
      if(validator){
        _this[validator](propname, val, required || false)
      }
    }else if(validator){
      _this[validator](propname, val, required || false)
    }else{
      _this.setData({
        [`errObj.${propname}`]: ''
      })
    }
  }
}

const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== 'string') {
    content = JSON.stringify(content);
  }
  window.localStorage.setItem(name, content);
}

const getStore = (name, isJson = true) => {
  if (!name) return;
  let info = window.localStorage.getItem(name)
  if(isJson){
    return info ? JSON.parse(info) : ''
  }else{
    return info
  }
}

const removeStore = name => {
  if (!name) return;
  window.localStorage.removeItem(name);
}

async function getNewId() {
  return new Promise((resolve, reject) => {
    request.psccgetId().then(res => {
      if(res.code == 200){
        resolve(res.data)
      } else {
        Message.error({message: res.msg || '数据获取出错，请稍后重试'})
        reject(false)
      }
    }).catch(e => {
      reject(false)
      console.log(e)
    })
  })
}

async function postRequestData(url, params) {
  return new Promise((resolve, reject) => {
    request[url](params).then(res => {
      if(res.code == 200){
        resolve(true)
      } else {
        Message.error({message: res.msg || '数据获取出错，请稍后重试'})
        reject(false)
      }
    }).catch(e => {
      reject(false)
      console.log(e)
    })
  })
}

async function getUrlDatas(url, params) {
  return new Promise((resolve, reject) => {
    request[url](params).then(res => {
      if(res.code == 200){
        resolve(res.data)
      } else {
        Message.error({message: res.msg || '数据获取出错，请稍后重试'})
        reject(false)
      }
    }).catch(e => {
      reject(false)
      console.log(e)
    })
  })
}

const getMonths = (function() {
  let counts = {
    normal: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
    leap:   [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  };
  return function(months = 1, startDate = new Date()) {
    if (!startDate) return "";
    let type = typeof startDate;
    if (type === "string") {
      startDate = new Date(startDate.replace(/-/g, "/"));
    } else if (type === "number") {
      startDate = new Date(startDate);
    }
    if (!startDate instanceof Date) return
    let endYear = startDate.getFullYear() + Math.ceil((months + startDate.getMonth()) / 12) - 1;
    let yearType = ((endYear % 4 == 0) && (endYear % 100 != 0)) || (endYear % 400 == 0) ? 'leap' : 'normal';
    let endMonth = (startDate.getMonth() + months) % 12;
    let endDate = Math.min(startDate.getDate(), counts[yearType][endMonth]);
    return dateFormat(new Date(endYear, endMonth, endDate), 'yyyy-MM-dd')
  };
}())

const download = (resp, name) => {
  let blob = new Blob([resp], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
  });
  // IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, name);
  } else {
    let downloadElement = document.createElement("a");
    let href = window.URL.createObjectURL(blob);
    downloadElement.href = href;
    downloadElement.download = name;
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
    window.URL.revokeObjectURL(href);
  }
}

const downImage = (imgsrc, name) => {
  let image = new Image();
  image.src = imgsrc + '?v=' + Math.random(); 
  image.crossOrigin = '*';
  image.onload = function () {
    let canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, image.width, image.height);
    let base64 = canvas.toDataURL('image/png');
    let tmpa = document.createElement("a");
    tmpa.download = name;
    tmpa.href = base64; 
    tmpa.target = base64; 
    tmpa.click(); 
  };
};

const downloadUrl = (href, name) => {
  let downloadElement = document.createElement("a")
  downloadElement.href = href
  downloadElement.download = name
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
  window.URL.revokeObjectURL(href)
}

const downloadPdf = (URLToPDF, name) => {
  let oReq = new XMLHttpRequest();
  oReq.open("GET", URLToPDF, true);
  oReq.responseType = "blob";
  oReq.onload = function() {
    let file = new Blob([oReq.response], { 
        type: 'application/pdf' 
    });
    saveAs(file, `${name}.pdf`);
  }
  oReq.send();
}

function saveAs(data, name) {
  const urlObject = window.URL || window.webkitURL || window;
  const export_blob = new Blob([data]);
  const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
  save_link.href = urlObject.createObjectURL(export_blob);
  save_link.download = name;
  save_link.click();
}

const getFixedNumber = (number, n = 4, flag = true) => {
  n = n ? parseInt(n) : 0;
  if(n <= 0) {
    return Math.round(number);
  }
  number = Math.round(number * Math.pow(10, n)) / Math.pow(10, n); //四舍五入
  number = Number(number).toFixed(n); //补足位数
  return flag ? parseFloat(number) : number
}

const getBodyInfo = (prop) => {
  return getStore('userInfo')?.bodyInfo[prop] || ''
}

const getUserInfo = (prop) => {
  return getStore('userInfo')?.userInfo[prop] || ''
}

const getMobilePhoneNumber = () => {
  return getStore('userInfo')?.bodyInfo?.mobilePhoneNumber || ''
}

const getBodyId = () => {
  return getStore('userInfo')?.bodyInfo?.bodyId || ''
}

const getUserName = () => {
  return getStore('userInfo')?.userInfo?.userName || ''
}

const getUserId = () => {
  return getStore('userInfo')?.userInfo?.userId || ''
}

const getOpenId = () => {
  return getStore('userInfo')?.userInfo?.openId || ''
}

const getBodyType = () => {
  return getStore('userInfo')?.bodyInfo?.bodyType || ''
}

const getBodyName = () => {
  return getStore('userInfo')?.bodyInfo?.bodyName || ''
}

const getBaseName = () => {
  return getStore('baseName', false) || ''
}

const isNum = (num) => {
  return !isNaN(parseFloat(num)) && isFinite(num); 
}

const sendMsg = (that, prop, type) => {
  that.$refs.ruleForm.validateField(prop, (valid) => {
    if(!valid){
      request.commonsend({
        type: type,
        mobile: that.ruleForm[prop]
      }).then(res => {
        if(res.code == 200){
          that.message('验证码发送成功', "success")
          count(that)
        } else {
          that.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    }
  })
}

const count = (that) => {
  let interval = setInterval(function () {
    let countdown = that.countdown;
    if (countdown == 0) {
      that.sendFlag = true
      that.countdown = CONSTPARAMS.countdown
      clearInterval(interval)
    } else {
      countdown--;
      that.sendFlag = false
      that.countdown = countdown
    }
  }, 1000)
}

const codeCheck = (type, mobile, code) => {
  return new Promise((resolve) => {
    if(!mobile || !code) {
      console.log(1)
      resolve(false)
      return
    }
    if(!baseValidate.tel(mobile)){
      resolve(false)
      return
    }
    request.commoncheck({
      type: type,
      mobile: mobile,
      code: code
    }).then(res  => {
      if(res.code != 200 || !res.data){
        Message.error({message: res.msg || ''})
        resolve(false)
      }else{
        resolve(true)
      }
    }).catch(e => {
      console.log(e)
      resolve(false)
    })
  })
}

const getQrUrl = (file) => {
  const url = window.webkitURL.createObjectURL(file) || window.URL.createObjectURL(file) || window.mozURL.createObjectURL(file)
  const qr = new QrCode()
  return qr.decodeFromImage(url)
}

const getQrCode = (file) => {
  const result = getQrUrl(file)
  return new Promise((resolve) => {
    return result.then(res => {
      if (res.data) {
        resolve(res.data)
      } else {
        resolve(false)
      }
    }).catch(err => {
      resolve(false)
    })
  })
}

const tableToNotIE = (table, name) => {
  let uri = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
    template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta charset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
    base64 = function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format = (s, c) => {
      return s.replace(/{(\w+)}/g, (m, p) => {
        return c[p]
      })
    }, 
    ctx = {
      worksheet: name,
      table,
    },
    link = document.createElement("a")
  link.setAttribute("href", uri + base64(format(template, ctx)))
  link.setAttribute("download", name)
  link.click()
}

const reGetAddr = () => {
  setStore('allAddress', [])
  request.commonareastree('').then(res => {
    if(res.code == 200){
      if(res.data){
        setStore('allAddress', res.data || [])
      }
    }
  }).catch(e => {
    console.log(e)
  })
}

function filterOne(dataList, value, type = ['areaName']) {
  let s = dataList.filter(function(item, index, arr) {
    for (let j = 0; j < type.length; j++) {
      if (item[type[j]] != undefined || item[type[j]] != null) {
        if (item[type[j]].indexOf(value) >= 0) {
          return item;
        }
      }
    }
  });
  return s
}

function getClientFlag(width = 1200) {
  let client = document.getElementById('app') && document.getElementById('app').clientWidth || 0
  return client < width ? false : true
}

function getTopHeight() {
  let getTopHeight = (document.querySelector('.page-table .title-tab')?.clientHeight || 0) 
    + (document.querySelector('.page-table .title-search')?.clientHeight || 0) 
    + (document.querySelector('.page-table .el-pagination')?.clientHeight || 0) 
    + CONSTPARAMS.staticHeight
  return getTopHeight
}

const transConstToObjs = prop => {
  if(prop){
    let allValues = CONSTPARAMS[prop],
      obj = {}
      if(allValues){
        for(let i = 0, len = allValues.length; i < len; i++){
          let item = allValues[i]
          obj[item.value] = item.label
        }
      }
    return obj
  }
}

const getConstToVal = (prop1, prop2) => {
  let objs = transConstToObjs(prop1)
  return objs[prop2]
}

function getSelLabel(arr, val, valueName = 'value', labelName = 'label') {
  let reVal = ''
  if(val){
    arr = arr || []
    arr.forEach(cur => {
      if(cur[valueName] == val){
        reVal = cur[labelName]
      }
    })
  }
  return reVal
}

function constGetSelLabel(prop, val, valueName = 'value', labelName = 'label') {
  return getSelLabel(CONSTPARAMS[prop], val, valueName, labelName)
}


function tranSels(arr, valueName = 'label') {
  let reVal = []
  arr = arr || []
  arr.forEach(cur => {
    if(!cur.isHidden){
      reVal.push({
        value: cur[valueName], 
        label: cur[valueName]
      })
    }
  })
  return reVal
}

function constTranSels(prop, valueName = 'label') {
  return tranSels(CONSTPARAMS[prop], valueName)
}

export default {
  downImage,
  downloadPdf,
  constGetSelLabel,
  constTranSels,
  tranSels,
  getSelLabel,
  getTopHeight,
  getClientFlag,
  filterOne,
  reGetAddr,
  postRequestData,
  downloadUrl,
  tableToNotIE,
  getQrCode,
  sendMsg,
  codeCheck,
  formatTime,
  dateFormat,
  uploadMedia,
  uploadFiles,
  getDays,
  getdefaultAddress,
  getAddresslist,
  getLocation,
  filterTree,
  getAddresByObj,
  requestAddress,
  addDays,
  getAddressByObj,
  arrToVideo,
  videoToArr,
  getUrlParam,
  getParamCode,
  checkDataValidates,
  checkSingleData,
  setStore,
  getStore,
  removeStore,
  getNewId,
  getMonths,
  transConstToObjs,
  getConstToVal,
  download,
  getUrlDatas,
  getBodyId,
  getBodyInfo,
  getUserInfo,
  getUserName,
  getUserId,
  getOpenId,
  getBodyName,
  getFixedNumber,
  getBaseName,
  getBodyType,
  isNum,
  getMobilePhoneNumber
}
