// 导入组件
import Vue from 'vue'
import Router from 'vue-router'
import asyncRoutes from './asyncRoutes'
import basic from '@/views/basic.vue'

// 启用路由
Vue.use(Router);

// 导出路由 
export default new Router({
  mode: 'history',
  base: '/',
  routes: [{
    path: '/login', //登录
    component: () => import('@/views/system/login.vue'),
    name: 'login',
    meta: {
      requireAuth: true,
      title: '登录'
    }
  },{
    path: '/register', //注册
    component: () => import('@/views/system/register.vue'),
    name: 'register',
    meta: {
      requireAuth: true,
      title: '注册'
    }
  },{
    path: '/ct', //追溯页面
    component: () => import('@/views/certificate/sourceWap.vue'),
    name: 'sourceWap',
    meta: {
      requireAuth: true,
      title: '农产品质量安全合格证全程追溯平台'
    }
  },{
    path: '/',
    component: basic,
    children: [
      ...asyncRoutes,
      {
        path: '*',
        component: () => import('@/views/home.vue'),
        name: 'home'
      }
    ]
  },]
})