import Vue from 'vue'
import App from './App.vue'
import Element from 'element-ui' 
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/common.css' // 引入全局样式
import router from './router/index';
import store from './vuex';
import { Message } from 'element-ui'
import { message } from '@/utils/message';
import CONSTPARAMS from '@/utils/const';
import Print from 'vue-print-nb'
import myImage from '@/components/myImages'
import axios from 'axios';
Vue.prototype.$axios = axios;
Vue.config.productionTip = false
Vue.use(Print); //注册
Vue.use(Element)
Vue.use(myImage)
Vue.prototype.message = message
import VueAMap from "vue-amap"
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: "db8abccfb6846d46b3543a201ad1116e",
  plugin: [
    "AMap.Autocomplete", //输入提示插件
    "AMap.PlaceSearch", //POI搜索插件
    "AMap.Scale", //右下角缩略图插件 比例尺
    "AMap.OverView", //地图鹰眼插件
    "AMap.ToolBar", //地图工具条
    "AMap.MapType", //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    "AMap.PolyEditor", //编辑 折线多，边形
    "AMap.CircleEditor", //圆形编辑器插件
    "AMap.Geolocation", //定位控件，用来获取和展示用户主机所在的经纬度位置
    'AMap.Geocoder'
  ],
  uiVersion: "1.0"
})
// 路由拦截器
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title}-${CONSTPARAMS.sysName}` : CONSTPARAMS.sysName
  if (to.matched.length != 0) {
    if (!to.meta.requireAuth) { // 判断该路由是否需要登录权限
      if (Boolean(localStorage.getItem("userInfo"))) {
        next();
      } else {
        Message.error({message: '暂未登录，即将跳转到登录页面...'})
        setTimeout(() => {
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
        }, 1000)
        
      }
    } else {
      next()
    }
  } else {
    next({
      path: '/login',
      query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
    })
  }
})

// new Vue({
//   el: '#app',
//   router,
//   store, 
//   components: { App },
//   template: '<App/>',
//   data: {
//       Bus: new Vue()
//   }
// })
export default new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})